import React, { useContext, useEffect, useState } from 'react';
import { ChevronDown, Select } from 'syngenta-digital-cropwise-react-ui-kit';
import './index.css';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { getUnitLabel } from 'helpers/unitsAbbreviation';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FormInputWithPrecisionDisplay from './components/FormInputWithPrecisionDisplay';
import featureFlagRenderItem from '../../helpers/featureFlagRenderItem';
import { Context } from '../Store';

const PriceUnitField = ({
  units,
  object,
  fieldNameInput,
  fieldNameUnit,
  defaultUnit,
  disabled,
  minWidthDropdown,
  customClassLabel,
  labelName,
  customLabel,
  required,
  onChange: onChangeExternal
}) => {
  const formikContext = useFormikContext();
  let values = {};
  let setFieldValue = () => {};
  if (!object && formikContext) {
    values = formikContext.values;
    setFieldValue = formikContext.setFieldValue;
  }

  const [{ organization }] = useContext(Context);

  const {
    releaseFeAllowUnitPriceChange,
    releaseFeAllowUnitPriceChangeByOrg
  } = useFlags();
  const isUnitChangeAllowed = featureFlagRenderItem(
    releaseFeAllowUnitPriceChange,
    releaseFeAllowUnitPriceChangeByOrg,
    organization?.id
  );

  const [fieldUnitValue, setFieldUnitValue] = useState(
    object?.[fieldNameUnit] || values?.[fieldNameUnit] || defaultUnit
  );

  useEffect(() => {
    setFieldUnitValue(
      object?.[fieldNameUnit] || values?.[fieldNameUnit] || defaultUnit
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object, setFieldUnitValue, fieldNameUnit, values?.[fieldNameUnit]]);

  const onChange = value => {
    if (object) {
      setFieldUnitValue(value);
      // eslint-disable-next-line no-param-reassign
      object[fieldNameUnit] = value;
      onChangeExternal(object[fieldNameInput], value);
      return;
    }
    onChangeExternal(values[fieldNameInput], value);
    setFieldValue(fieldNameUnit, value);
  };

  return (
    <div className="flex flex-col price-unit-field">
      <div className="flex-1">
        {customLabel || (
          <label
            className={`gr-text-neutral-600 gr-text-sm gr-antialiased gr-inline-flex gr-items-center gr-align-middle gr-absolute gr--mt-6 ${customClassLabel}`}
          >
            <div className="gr-flex gr-items-center ">{labelName}</div>
          </label>
        )}
      </div>
      <div className="flex flex-row">
        <div className="flex-1">
          <FormInputWithPrecisionDisplay
            object={object}
            fieldName={fieldNameInput}
            precision={2}
            id={fieldNameInput?.toLowerCase()}
            isRequired={required}
            inputPrefix={<span className="text-neutral-60">$</span>}
            inputSuffix={<span className="text-neutral-60">per</span>}
            disabled={disabled}
            onControlledChange={value => {
              if (object) {
                onChangeExternal(object[fieldNameInput], value);
                return;
              }
              onChangeExternal(values[fieldNameInput], value);
            }}
          />
        </div>
        <div className="flex-1" style={{ minWidth: minWidthDropdown }}>
          <div className="relative flex flex-col">
            <Select
              data-testid={`${fieldNameUnit}-unit-input`}
              size="middle"
              suffixIcon={<ChevronDown />}
              onChange={onChange}
              defaultValue={defaultUnit}
              value={
                object
                  ? fieldUnitValue || defaultUnit
                  : values[fieldNameUnit] || defaultUnit
              }
              dropdownMatchSelectWidth={false}
              optionLabelProp="label"
              optionFilterProp="label"
              showSearch
              disabled={disabled || !isUnitChangeAllowed}
              required
              getPopupContainer={triggerNode => triggerNode.parentNode}
            >
              {units.map(unit => (
                <Select.Option
                  key={unit.key}
                  value={unit.key}
                  label={getUnitLabel(unit.key)}
                >
                  {getUnitLabel(unit.key)}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};

PriceUnitField.defaultProps = {
  object: null,
  disabled: false,
  defaultUnit: null,
  minWidthDropdown: '50%',
  labelName: 'Price/Unit',
  required: false,
  onChange: () => {},
  customClassLabel: '',
  customLabel: null
};

PriceUnitField.propTypes = {
  units: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  object: PropTypes.object,
  fieldNameInput: PropTypes.string.isRequired,
  fieldNameUnit: PropTypes.string.isRequired,
  defaultUnit: PropTypes.string,
  disabled: PropTypes.bool,
  minWidthDropdown: PropTypes.string,
  labelName: PropTypes.node,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  customClassLabel: PropTypes.string,
  customLabel: PropTypes.node
};

export default PriceUnitField;
