import {
  fertilizerSolidStandardUnitsList,
  fertilizerLiquidStandardUnitsList
} from 'components/Modals/CustomProductModal/components/FertilizerProductForm';
import _ from 'lodash';

export const serviceUnits = [
  {
    key: 'acre',
    value: 'acre'
  },
  {
    key: 'hectare',
    value: 'hectare'
  },
  {
    key: 'hour',
    value: 'hour'
  },
  {
    key: 'day',
    value: 'day'
  }
];

export const fertUnits = [
  {
    key: 'centiliter',
    value: 'centiliter'
  },
  {
    key: 'cubic foot',
    value: 'cubic foot'
  },
  {
    key: 'cubic inch',
    value: 'cubic inch'
  },
  {
    key: 'cubic meter',
    value: 'cubic meter'
  },
  {
    key: 'cubic yard',
    value: 'cubic yard'
  },
  {
    key: 'fluid ounce',
    value: 'fluid ounce'
  },
  {
    key: 'milliliter',
    value: 'milliliter'
  },
  {
    key: 'pint',
    value: 'pint'
  },

  {
    key: 'gram',
    value: 'gram'
  },

  {
    key: 'long ton',
    value: 'long ton'
  },

  {
    key: 'ounce',
    value: 'ounce'
  }
];

export const fertCombinedUnits = _.uniqBy(
  fertUnits.concat(
    fertilizerLiquidStandardUnitsList,
    fertilizerSolidStandardUnitsList
  ),
  'key'
);
