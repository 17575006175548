import { useEffect, useState, useContext, useCallback, useRef } from 'react';
import axios from 'axios';
import { getAccessToken } from 'utilities/base-auth';
import { MASTERLIST_PROXY_URL } from 'utilities/apiConstants';
import { product as productApi } from 'utilities/api';
import alphanumSort from 'helpers/sorters';
import catchCancel from 'helpers/catchCancel';
import { Context } from 'components/Store';
import { filterByType } from './productHelper';

const useProductSearch = (debouncedSearchTerm = '', debouncedTypeFilter) => {
  const [, dispatch] = useContext(Context);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [customProducts, setCustomProducts] = useState([]);

  const prevSearchTerm = useRef(null);
  const prevTypeFilter = useRef(null);
  const prevPage = useRef(null);
  const didSearchTermChange = useRef(null);
  const didTypeFilterChange = useRef(null);
  const didPageChange = useRef(null);

  const loadCustomProducts = async () => {
    if (!didSearchTermChange.current) {
      return customProducts;
    }

    const body = [
      {
        key: 'name',
        operation: ':',
        value: debouncedSearchTerm,
        orPredicate: false
      }
    ];

    const searchCustomProductApi = productApi.createChildApi({
      action: 'product/search'
    });

    const { promise } = searchCustomProductApi.create(body);

    return promise
      .then(({ data }) => {
        let mappedData = data.map(custom => ({
          ...custom,
          custom: true,
          stdpackageunit: custom.standardUnit,
          stdunit: custom.standardUnit,
          productDensity: custom.density,
          producttype: custom.type,
          productId: custom.id
        }));

        mappedData = filterByType(mappedData, debouncedTypeFilter);
        setCustomProducts(mappedData);
        return mappedData;
      })
      .catch(catchCancel)
      .catch(() => {
        setCustomProducts([]);
        return [];
      });
  };

  const loadSearchProducts = useCallback(
    async page => {
      const normalizedSearch = encodeURIComponent(
        debouncedSearchTerm?.trim().toLowerCase()
      );
      return axios
        .get(
          `${MASTERLIST_PROXY_URL}/products/search?productName=${normalizedSearch}&pageNo=${page}`,
          {
            headers: {
              common: {
                Authorization: `Bearer ${getAccessToken()}`
              }
            }
          }
        )
        .then(response => {
          setTotalPages(
            Math.ceil(response.data.totalResults / response.data.resultsPerPage)
          );
          const activeProductsFilter = response.data.items.filter(
            eachData => eachData.status === 'Active'
          );

          setHasNextPage(Boolean(activeProductsFilter.length));

          return activeProductsFilter;
        })
        .catch(catchCancel)
        .catch(() => {
          setProducts([]);
          return [];
        });
    },
    [debouncedSearchTerm]
  );

  const searchProductById = useCallback(async productId => {
    setLoading(true);
    return axios
      .get(
        `${MASTERLIST_PROXY_URL}/products/${productId}/?format=json&c=en-US`,
        {
          headers: {
            common: {
              Authorization: `Bearer ${getAccessToken()}`
            }
          }
        }
      )
      .then(response => {
        return response.data;
      })
      .catch(catchCancel)
      .catch(() => {
        return {};
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const loadAllProducts = (page, resetProducts) => {
    const productsPromise = loadSearchProducts(page);
    const customProductsPromise = loadCustomProducts();

    setLoading(true);

    Promise.all([productsPromise, customProductsPromise])
      .then(([activeProductsFilter, customProductsFilter]) => {
        if (resetProducts) {
          const sortedArray = alphanumSort([
            ...activeProductsFilter,
            ...customProductsFilter
          ]);

          setFilteredProducts(filterByType(sortedArray, debouncedTypeFilter));
          setProducts(sortedArray);
        } else {
          const combinedProducts = [...products, ...activeProductsFilter];
          const sortedArray = alphanumSort(combinedProducts);
          setFilteredProducts(filterByType(sortedArray, debouncedTypeFilter));
          setProducts(sortedArray);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    didPageChange.current = prevPage.current !== pageNo;
    didSearchTermChange.current =
      prevSearchTerm.current !== debouncedSearchTerm;
    didTypeFilterChange.current =
      prevTypeFilter.current !== debouncedTypeFilter;

    if (
      debouncedSearchTerm?.length >= 3 &&
      (didPageChange.current ||
        didSearchTermChange.current ||
        didTypeFilterChange.current)
    ) {
      const page = didSearchTermChange.current ? 1 : pageNo;
      prevSearchTerm.current = debouncedSearchTerm;
      prevTypeFilter.current = debouncedTypeFilter;
      prevPage.current = page;

      if (page !== pageNo) {
        setPageNo(page);
      }

      loadAllProducts(page, didSearchTermChange.current);
    } else {
      setProducts([]);
      setFilteredProducts([]);
      setPageNo(1);
      setTotalPages(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, debouncedSearchTerm, debouncedTypeFilter, pageNo]);

  return {
    loadSearchProducts,
    searchProductById,
    products,
    loading,
    setLoading,
    setProducts,
    filteredProducts,
    setFilteredProducts,
    totalPages,
    setTotalPages,
    pageNo,
    setPageNo,
    hasNextPage,
    setHasNextPage
  };
};

export default useProductSearch;
