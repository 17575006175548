import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@agconnections/grow-ui';
import useCompanies from 'hooks/useCompanies';

import { AgVendMatchContext } from 'screens/Integrations/AgVend/AgVendMatches/context';

import RetailerListItem from './components/RetailerListItem';

const RetailerList = ({ retailers, onSelectMatch }) => {
  const [companyMatches, setCompanyMatches] = useState([]);
  const {
    companies,
    loadCompanies,
    loading: isLoadingCompanies
  } = useCompanies();
  const [loadingNewCompanies, setLoadingNewCompanies] = useState(false);

  const {
    state: { matches },
    isLoading: isLoadingMatches
  } = useContext(AgVendMatchContext);

  useEffect(() => {
    loadCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (matches.length > 0) {
      setCompanyMatches(
        matches
          .filter(({ type }) => type === 'Company')
          .map(match => ({
            company: companies?.find(
              company => match.cwfEntityId === company.id
            ),
            ...match
          }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies, matches]);

  // Use effect triggered when create a new company
  useEffect(() => {
    if (
      companyMatches.length > 0 &&
      companyMatches
        .filter(({ type }) => type === 'Company')
        .some(match => !match.company)
    ) {
      setLoadingNewCompanies(true);
      loadCompanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyMatches]);

  useEffect(() => {
    setLoadingNewCompanies(false);
  }, [companies.length]);

  const isLoading =
    isLoadingCompanies || isLoadingMatches || loadingNewCompanies;

  return (
    <div className="grid w-full h-full">
      {isLoading && (
        <div className="h-48 mr-16 mt-16 mb-10">
          <Spinner />
        </div>
      )}
      {!isLoading &&
        retailers?.map(retailer => (
          <RetailerListItem
            key={retailer.externalId}
            retailer={retailer}
            matchedRetailer={
              companyMatches.find(
                match => match.vendorEntityId === retailer.externalId
              )?.company
            }
            handleSelect={cwfSelectedCompany =>
              onSelectMatch(retailer, cwfSelectedCompany)
            }
          />
        ))}
    </div>
  );
};

RetailerList.propTypes = {
  retailers: PropTypes.arrayOf(
    PropTypes.shape({
      externalId: PropTypes.string,
      retailerName: PropTypes.string
    })
  ).isRequired,
  onSelectMatch: PropTypes.func.isRequired
};

export default RetailerList;
