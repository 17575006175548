import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { featureCollection } from '@turf/helpers';
import {
  DragCircleMode,
  DirectMode,
  SimpleSelectMode
} from 'mapbox-gl-draw-circle';
import { isUndefined, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Context } from 'components/Store';
import useTwoWayState from 'hooks/useTwoWayState';
import SelectOnlyMode from './SelectOnlyMode';
import PropertyMap from './PropertyMap';
import useMapData from '../../../hooks/useMapData';
import { featuresMatch } from '../../../helpers/propertyDataHelpers';
import PropertyMapContext from '../PropertyMapContext';
import { MapEventContext } from './MapEventContext';
import {
  getAllFeatures,
  getGeoJSONCollectionFeatures,
  getFeatureWithParentId,
  getSyncFeaturesForUpdate
} from '../../../helpers/mapApiHelpers';
import { LAND_TYPES, DEFAULT_ZOOM } from '../../../helpers/constants';
import MapEventDisplay from './MapEventDisplay';
import WithExportPreview from './WithExportPreview';

export const handleDrawCreate = (
  featuresToSave,
  featureIdsToDelete,
  setFeatures,
  parentId
) => {
  for (let i = 0; i < featuresToSave.length; i += 1) {
    // eslint-disable-next-line no-param-reassign
    if (!featuresToSave[i].id) featuresToSave[i].id = uuidv4();
  }
  // if a 2nd arg is provided, that is for features to be removed
  const filterPredicate = featureIdsToDelete
    ? ({ id }) => !featureIdsToDelete.includes(id)
    : () => true;
  setFeatures(previousFeatures => {
    const updatedFeatures = featuresToSave
      .filter(feature => feature.geometry?.coordinates)
      .map(item => getFeatureWithParentId(item, { _id: parentId }));
    return previousFeatures.filter(filterPredicate).concat(updatedFeatures);
  });
};

export const handleDrawUpdate = (
  shapeId,
  changedFeatures,
  setFeatures,
  setNewFieldFeatures,
  syncedFeatures
) => {
  setFeatures(previousFeatures =>
    previousFeatures.map(featureItem => {
      const changedFeature = changedFeatures.find(
        item => item.id === featureItem.id
      );
      return {
        ...featureItem,
        geometry: changedFeature
          ? changedFeature.geometry
          : featureItem.geometry
      };
    })
  );
  setNewFieldFeatures(
    changedFeatures.filter(feature => feature.geometry?.coordinates)
  );
  // eslint-disable-next-line no-param-reassign
  syncedFeatures.current = getSyncFeaturesForUpdate(
    syncedFeatures.current,
    shapeId
  );
};

export const handleDrawDelete = (deletedIds, setFeatures) => {
  setFeatures(previousFeatures =>
    previousFeatures.filter(({ id }) => !deletedIds.includes(id))
  );
};

const PropertyMapWrapper = ({
  currentCoordinates,
  displayOnly,
  fields,
  geoJSONCollection,
  liveUpdate,
  onClickShape,
  onFeaturesChange,
  onSelectFeatures,
  parentId,
  selectedFeatureIds,
  setCurrentCoordinates,
  setZoom,
  stretch,
  zoom,
  fieldsLoading,
  labelName,
  shapeIdSelected,
  isCreate,
  setNewFieldFeatures,
  customStyles
}) => {
  const {
    selectedShapeIds,
    setSelectedShapeIds,
    setSelectedShapesExternally,
    internalShapeSelectionChanges,
    mapChangeEvent,
    newFeatures,
    addNewMapFeature,
    addNewMapFeatures,
    archiveMapHistoryFeature
  } = useMapData();

  const [markerOn, setMarkerOn] = useState(false);
  const [{ selectedProperty, fieldsToMassiveAssign }] = useContext(Context);
  const modes = {
    ...MapboxDraw.modes,
    direct_select: DirectMode,
    simple_select: SimpleSelectMode,
    drag_circle: DragCircleMode,
    select_only: SelectOnlyMode
  };

  const defaultMode =
    onSelectFeatures && displayOnly ? 'select_only' : 'simple_select';
  const comparator = useCallback(featuresMatch, []);

  const [
    features,
    setFeatures,
    externalSetFeatures,
    internalFeaturesChanges
  ] = useTwoWayState([], comparator);

  const { location } = useHistory();

  const [mode, setMode] = useState(defaultMode);
  const [viewContext] = useState(LAND_TYPES.FIELD);
  const syncedFeatures = useRef([]);

  // Live update allows changes to be made to api in real time
  useEffect(() => {
    // get featureCollection from geoJSONCollection of from fields
    let changedFeatures = [];
    changedFeatures = geoJSONCollection?.features?.length
      ? getGeoJSONCollectionFeatures(geoJSONCollection)
      : getAllFeatures(fields);
    if (!isUndefined(changedFeatures)) {
      externalSetFeatures(changedFeatures);
    }
  }, [fields, geoJSONCollection, externalSetFeatures]);

  // pick up changes to features state, trigger onFeaturesChange, and track synced features
  useEffect(() => {
    if (!isUndefined(internalFeaturesChanges)) {
      onFeaturesChange(internalFeaturesChanges);
    }
  }, [internalFeaturesChanges, onFeaturesChange]);

  // changes to selectedFeatureIds prop are external, update accordingly
  useEffect(() => {
    if (!isUndefined(selectedFeatureIds)) {
      setSelectedShapesExternally(selectedFeatureIds);
    }
  }, [selectedFeatureIds, setSelectedShapesExternally]);

  // notify the parent that selection changes were triggered from the map
  useEffect(() => {
    if (onSelectFeatures && !isUndefined(internalShapeSelectionChanges)) {
      onSelectFeatures(internalShapeSelectionChanges);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalShapeSelectionChanges]);

  useEffect(() => {
    if (!Array.isArray(shapeIdSelected)) {
      setSelectedShapeIds([shapeIdSelected]);
    } else {
      setSelectedShapeIds(shapeIdSelected);
    }
  }, [shapeIdSelected, setSelectedShapeIds]);

  const modeReset = () => {
    setMode(defaultMode);
  };

  const collection = featureCollection(features);

  return (
    <MapEventContext>
      <PropertyMapContext.Provider
        value={{
          addNewMapFeature,
          addNewMapFeatures,
          archiveMapHistoryFeature,
          onClickShape,
          fields,
          mapChangeEvent,
          markerOn,
          newFeatures,
          selectedShapeIds,
          setMarkerOn,
          setSelectedShapeIds
        }}
      >
        <WithExportPreview fields={fieldsToMassiveAssign}>
          <PropertyMap
            currentCoordinates={currentCoordinates}
            setCurrentCoordinates={setCurrentCoordinates}
            displayOnly={displayOnly}
            geoJSONCollection={collection}
            customStyles={
              !isEmpty(selectedProperty) ||
              fieldsToMassiveAssign.length ||
              customStyles
                ? {
                    focused: {
                      fillColor: 'rgba(49, 180, 242, 0.4)',
                      fillOutlineColor: 'rgba(49, 180, 242, 1)'
                    },
                    ...customStyles
                  }
                : null
            }
            handleDrawCreate={(featuresToSave, featureIdsToDelete) =>
              handleDrawCreate(
                featuresToSave,
                featureIdsToDelete,
                setFeatures,
                parentId
              )
            }
            handleDrawUpdate={(shapeId, changedFeatures) =>
              handleDrawUpdate(
                shapeId,
                changedFeatures,
                setFeatures,
                setNewFieldFeatures,
                syncedFeatures
              )
            }
            handleDrawDelete={deletedIds =>
              handleDrawDelete(deletedIds, setFeatures)
            }
            markerOn={markerOn}
            mode={mode}
            modeReset={modeReset}
            setMode={setMode}
            modes={modes}
            liveUpdate={liveUpdate}
            viewContext={viewContext}
            zoom={zoom}
            setZoom={setZoom}
            stretch={stretch}
            fieldsLoading={fieldsLoading}
            labelName={
              location?.label === null || location?.label === undefined
                ? labelName
                : location?.label
            }
            isCreate={isCreate}
          />
        </WithExportPreview>
      </PropertyMapContext.Provider>
      <MapEventDisplay />
    </MapEventContext>
  );
};

PropertyMapWrapper.defaultProps = {
  currentCoordinates: null,
  customStyles: false,
  displayOnly: false,
  fields: [],
  geoJSONCollection: null,
  liveUpdate: true,
  onClickShape: () => {},
  onFeaturesChange: () => {},
  onSelectFeatures: null,
  parentId: null,
  selectedFeatureIds: undefined,
  setCurrentCoordinates: () => {},
  setZoom: () => {},
  stretch: false,
  zoom: DEFAULT_ZOOM,
  shapeIdSelected: null,
  isCreate: false,
  setNewFieldFeatures: () => {},
  labelName: ''
};

PropertyMapWrapper.propTypes = {
  currentCoordinates: PropTypes.arrayOf(PropTypes.number),
  customStyles: PropTypes.objectOf(
    PropTypes.shape({
      fillColor: PropTypes.string,
      fillOutlineColor: PropTypes.string
    })
  ),
  displayOnly: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  geoJSONCollection: PropTypes.object,
  liveUpdate: PropTypes.bool,
  onClickShape: PropTypes.func,
  onFeaturesChange: PropTypes.func,
  onSelectFeatures: PropTypes.func,
  parentId: PropTypes.string,
  selectedFeatureIds: PropTypes.arrayOf(PropTypes.string),
  setCurrentCoordinates: PropTypes.func,
  setZoom: PropTypes.func,
  stretch: PropTypes.bool,
  zoom: PropTypes.arrayOf(PropTypes.number),
  fieldsLoading: PropTypes.bool.isRequired,
  labelName: PropTypes.string,
  shapeIdSelected: PropTypes.string,
  isCreate: PropTypes.bool,
  setNewFieldFeatures: PropTypes.func
};

export default PropertyMapWrapper;
