import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { paths } from 'routes/paths';
import { Button } from '@agconnections/grow-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { determineIntegrationContext } from 'screens/Integrations/helpers/determineIntegrationContext';
import useSubmissionUrlHandler from 'screens/Integrations/hooks/useSubmissionUrlHandler';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import getSubmissionButtonLabel from 'screens/Integrations/helpers/getSubmissionButtonLabel';

import './index.css';
import { Context } from 'components/Store';

const SubmissionFooter = ({
  nextDisabled,
  onNext,
  nextLabel,
  loading,
  setModalSubmissionState,
  isLastStep,
  integrationType,
  isBottomFixed
}) => {
  const history = useHistory();
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const {
    state: { canGoNext, step },
    releaseStepForward,
    goBack
  } = useContext(determineIntegrationContext(integrationType));

  const { comingFromView } = useSubmissionUrlHandler();
  const [{ isSidebarOpen }] = useContext(Context);

  const handleCancel = () => {
    triggerAnalyticsEvent(events.epic.Integrations.submissionCanceled, {
      vendor: integrationType
    });
    history.replace(`${paths.integrations}/${integrationType}/landing-page`);
  };

  const handleBackButton = () => {
    if (step === 1) {
      return handleCancel();
    }
    return goBack();
  };

  useEffect(() => {
    if (isLastStep) releaseStepForward();
  }, [isLastStep, releaseStepForward]);

  const buttonLabel = getSubmissionButtonLabel({
    isLastStep,
    comingFromView,
    integrationType,
    nextLabel
  });

  return (
    <div
      className={
        isBottomFixed
          ? `fixed-footer-manipulation ${isSidebarOpen && 'sidebar-opened'}`
          : 'h-24 w-full'
      }
      style={{ zIndex: isBottomFixed ? '9' : 'auto' }}
      data-testid="submission-review-footer"
      data-html2canvas-ignore
    >
      <div className="flex flex-row border-t-2 bg-white w-auto justify-between px-4 py-6 h-auto">
        <Button type="outline" onClick={handleCancel}>
          Cancel
        </Button>
        <div className="flex flex-row gap-6">
          <Button
            type="outline"
            onClick={handleBackButton}
            icon={<FontAwesomeIcon fixedWidth size="1x" icon={faChevronLeft} />}
          >
            Back
          </Button>
          <Button
            type="primary"
            disabled={loading || !canGoNext || nextDisabled}
            dataTestId="footer-next-button"
            onClick={() => {
              if (isLastStep) {
                setModalSubmissionState(true);
              } else if (onNext && !nextDisabled) {
                onNext();
              }
            }}
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

SubmissionFooter.propTypes = {
  nextDisabled: PropTypes.bool,
  nextLabel: PropTypes.string,
  loading: PropTypes.bool,
  onNext: PropTypes.func,
  setModalSubmissionState: PropTypes.func,
  isLastStep: PropTypes.bool,
  integrationType: PropTypes.string,
  isBottomFixed: PropTypes.bool
};

SubmissionFooter.defaultProps = {
  nextDisabled: false,
  nextLabel: 'Next',
  loading: false,
  onNext: () => {},
  setModalSubmissionState: () => {},
  isLastStep: false,
  integrationType: 'Integration Label',
  isBottomFixed: false
};

export default SubmissionFooter;
