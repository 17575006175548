import React from 'react';
import { Spinner } from '@agconnections/grow-ui';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import ClickableDiv from 'components/ClickableDiv';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import FormNavGuard from 'components/FormNavGuard';
import { paths } from 'routes/paths';
import PropertyDetailsToast from 'screens/Property/PropertiesLanding/components/PropertyDetailsToast';
import PropertyMapWrapper from '../PropertyLandingMap/PropertyMapWrapper';
import FieldCard from './components/FieldCard';
import PropertySelector from './components/PropertySelector';
import { CREATION_STATES } from './helpers.js/constants';
import schema from './helpers.js/schema';
import usePropertyFeatures from './hooks/usePropertyFeatures';

const LEAVING_CROP_ZONE =
  'Are you sure you do not want to create a cropzone also?';

const PropertyCreate = () => {
  const { fieldId, action } = useParams();

  const isCreating = action === 'create';

  const history = useHistory();
  const { state } = useLocation();

  const {
    redirect,
    setRedirect,
    formValues,
    creationState,
    newFieldFeatures,
    openModal,
    handleModalCancel,
    handleModalClose,
    handleModalConfirm,
    isBackArrowClicked,
    handleBackArrowCancel,
    chkNavGuardDisabled,
    navGuardDisabled,
    handleSaveAndCreateCropZone,
    handleSaveAndRedirectToProperties,
    handleCancelBackButton,
    setChkNavGuardDisabled,
    setIsBackArrowClicked,
    handleCreateFarm,
    farms,
    fieldToEdit,
    geoJSONCollection,
    canShowMap,
    shapeIds,
    handleFeaturesChange,
    handleFeaturesSelect,
    fieldZoom,
    setZoom,
    setNewFieldFeatures,
    isToastVisible,
    setIsToastVisible,
    saveProperty,
    fieldCentroidCoordinates
  } = usePropertyFeatures(fieldId, action, history, state);
  return (
    <>
      {redirect && <Redirect push to={`${redirect}`} />}
      <Breadcrumb disabled hideCropSeasonDropdown={!isCreating}>
        <Breadcrumb.Item
          title="Property"
          value="All Properties"
          to={paths.properties}
        />
        <Breadcrumb.Item
          title="Field"
          value={fieldId ? 'Edit Field' : 'New Field'}
          isLast
        />
      </Breadcrumb>
      <div data-testid="property-create-screen">
        <Formik
          initialValues={formValues}
          enableReinitialize
          onSubmit={values => saveProperty(values, !!fieldId)}
          validationSchema={schema}
          validateOnChange
          validateOnMount
        >
          {({ values, setSubmitting, isValid, isSubmitting }) => {
            const canSave =
              creationState !== CREATION_STATES.SAVING &&
              isValid &&
              newFieldFeatures?.length > 0;

            return (
              <>
                <ConfirmationModal
                  open={openModal}
                  handleCancel={() => handleModalCancel(values, setSubmitting)}
                  handleClose={() => handleModalClose(setSubmitting)}
                  handleConfirm={() =>
                    handleModalConfirm(values, setSubmitting)
                  }
                >
                  Are you sure you want to create just a field and not a crop
                  zone with it?
                </ConfirmationModal>
                <ConfirmationModal
                  open={isBackArrowClicked}
                  handleCancel={handleBackArrowCancel}
                  handleConfirm={() => setRedirect(paths.properties)}
                  handleClose={handleBackArrowCancel}
                >
                  Are you sure you want to leave this page?
                </ConfirmationModal>
                <FormNavGuard
                  customText={!fieldId ? null : LEAVING_CROP_ZONE}
                  disabled={chkNavGuardDisabled || navGuardDisabled.current}
                  alwaysShow={!!fieldId}
                />
                <div className="bg-white flex w-full h-full">
                  <div
                    data-testid="property-create-left-panel"
                    className="flex flex-col w-1/3 h-auto m-4 relative"
                  >
                    <ClickableDiv className="flex-1 h-full">
                      <FieldCard
                        features={newFieldFeatures}
                        saveAndCreateCZ={() =>
                          handleSaveAndCreateCropZone(values, setSubmitting)
                        }
                        saveAndDirectToProperties={() =>
                          handleSaveAndRedirectToProperties(
                            values,
                            setSubmitting
                          )
                        }
                        canSave={canSave}
                        cancelBackButton={handleCancelBackButton}
                        label={action}
                        fieldId={fieldId}
                        setChkNavGuardDisabled={setChkNavGuardDisabled}
                        setIsBackArrowClicked={setIsBackArrowClicked}
                        chkSubmitStatus={isSubmitting}
                      >
                        <PropertySelector
                          onCreate={handleCreateFarm}
                          items={farms}
                          isDisabled={!isCreating}
                          selectedValue={fieldToEdit?.propertyId}
                          fieldsAndAreasGeoJSONCollection={geoJSONCollection}
                        />
                      </FieldCard>
                    </ClickableDiv>
                  </div>
                  <div
                    data-testid="property-create-right-panel"
                    className="relative w-full"
                    id="map-wrapper-container"
                  >
                    {canShowMap ? (
                      <PropertyMapWrapper
                        shapeIdSelected={shapeIds}
                        geoJSONCollection={geoJSONCollection}
                        onFeaturesChange={handleFeaturesChange}
                        onSelectFeatures={handleFeaturesSelect}
                        liveUpdate={false}
                        zoom={fieldZoom}
                        setZoom={setZoom}
                        isCreate={!fieldId}
                        setNewFieldFeatures={setNewFieldFeatures}
                        currentCoordinates={fieldCentroidCoordinates}
                      />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
        {isToastVisible && (
          <PropertyDetailsToast
            state="error"
            selectedProperty={{ type: fieldToEdit?.$landType }}
            showEditToast={isToastVisible}
            setShowEditToast={setIsToastVisible}
          />
        )}
      </div>
    </>
  );
};

export default PropertyCreate;
