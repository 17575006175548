import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@agconnections/grow-ui';
import { useLocation, useParams } from 'react-router-dom';
import useInventoryDocumentationData from '../../hooks/useInventoryDocumentationData';
import ProductDetailsTableHeader, {
  PRODUCT_DETAIL_TABLES
} from '../ProductDetailTableHeader';
import ProductDetailDocumentation from '../ProductDetailDocumentation';
import ProductDetailREIPHI from '../ProductDetailREIPHI';
import { ProductDetailsContext } from '../../context/ProductDetailsProvider';

const ProductDetailTabs = ({ product }) => {
  const location = useLocation();
  const { isUpdateListPrice } = useContext(ProductDetailsContext);
  const { id: productId } = useParams();
  const {
    dataDocumentation,
    isLoading: docsLoading,
    openFile,
    error: docsError
  } = useInventoryDocumentationData(productId);

  const [cropSeasons, setCropSeasons] = useState([]);
  const [dateRange, setDateRange] = useState(null);

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const TableComponent =
    PRODUCT_DETAIL_TABLES[queryParams.get('table')] ??
    PRODUCT_DETAIL_TABLES.invoices;

  const displayDocsTab =
    dataDocumentation?.documentdescriptions?.length &&
    !product.custom &&
    !docsLoading &&
    !docsError;

  const productDetails = {
    listPrice: product?.averagePricePerUnit,
    listPriceUnit: product?.averagePricePerUnitUnit ?? product?.stdUnit,
    productName: product?.productName,
    stdPackageUnit: product?.stdPackageUnit,
    stdUnit: product?.stdUnit
  };

  return (
    <div className="h-full overflow-hidden px-4">
      <Tabs
        pane
        className="flex flex-col h-full w-full"
        contentClassName="h-full overflow-hidden pb-4"
      >
        <Tabs.Tab label="Inventory">
          <ProductDetailsTableHeader
            cropSeasons={cropSeasons}
            onChangeCropSeasons={setCropSeasons}
            onChangeDateRange={setDateRange}
            dateRange={dateRange}
          />
          <TableComponent
            cropSeasons={cropSeasons}
            dateRange={dateRange}
            productDetails={productDetails}
          />
        </Tabs.Tab>

        {displayDocsTab ? (
          <Tabs.Tab label="Documentation" disabled={isUpdateListPrice}>
            <ProductDetailDocumentation
              documentation={dataDocumentation}
              openFile={openFile}
            />
          </Tabs.Tab>
        ) : null}
        <Tabs.Tab label="REI/PHI" disabled={isUpdateListPrice}>
          <ProductDetailREIPHI />
        </Tabs.Tab>
      </Tabs>
    </div>
  );
};

ProductDetailTabs.propTypes = {
  product: PropTypes.shape({
    custom: PropTypes.bool,
    averagePricePerUnit: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    stdPackageUnit: PropTypes.string,
    stdUnit: PropTypes.string,
    averagePricePerUnitUnit: PropTypes.string,
    productName: PropTypes.string
  }).isRequired
};

export default ProductDetailTabs;
