import { yearMonthDayFormat } from 'helpers/dateFormat';

const basicIsFilter = (table, column, value) => ({
  // eslint-disable-next-line
  $schema: 'http://powerbi.com/product/schema#advanced',
  target: {
    table,
    column
  },
  logicalOperator: 'And',
  conditions: [
    {
      operator: 'Is',
      value
    }
  ]
});

export default (formConfig, orgId, values, timezoneOffsetFormat) => {
  const filters = [];
  const tableData = {
    tableName: 'filter_params',
    orgIdColumnName: 'rp_org_id',
    cropSeasonColumnName: 'rp_cropseason_ids',
    fromDateColumnName: 'rp_start_date',
    toDateColumnName: 'rp_end_date',
    cropZoneIdColumnName: 'rp_cropzone_ids',
    taskIdColumnName: 'rp_task_ids',
    productIdColumnName: 'rp_product_ids',
    timezoneOffsetColumnName: 'rp_tz_offset',
    planIdColumnName: 'rp_plan_ids',
    mapStyleColumnName: 'rp_map_type'
  };

  const orgIdFilter = basicIsFilter(
    tableData.tableName,
    tableData.orgIdColumnName,
    orgId
  );
  filters.push(orgIdFilter);

  if (formConfig.showTimezoneOffset) {
    const rpTzOffset =
      timezoneOffsetFormat === 'iana'
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : new Date().getTimezoneOffset();
    const timezoneOffsetFilter = basicIsFilter(
      tableData.tableName,
      tableData.timezoneOffsetColumnName,
      rpTzOffset
    );
    filters.push(timezoneOffsetFilter);
  }

  if (formConfig.showCropSeasons) {
    const appliedCropSeasonFilter = basicIsFilter(
      tableData.tableName,
      tableData.cropSeasonColumnName,
      values.selectedCropSeasons?.map(({ id }) => id)?.join(',')
    );
    filters.push(appliedCropSeasonFilter);
  }
  if (formConfig.showDateRange) {
    const appliedFromDateFilter = basicIsFilter(
      tableData.tableName,
      tableData.fromDateColumnName,
      yearMonthDayFormat(values.cropSeasonStartDate)
    );
    filters.push(appliedFromDateFilter);
    const appliedToDateFilter = basicIsFilter(
      tableData.tableName,
      tableData.toDateColumnName,
      yearMonthDayFormat(values.cropSeasonEndDate)
    );
    filters.push(appliedToDateFilter);
  }

  if (formConfig.showPlans) {
    const appliedPlansFilter = basicIsFilter(
      tableData.tableName,
      tableData.planIdColumnName,
      values.selectedPlans?.join(',')
    );
    filters.push(appliedPlansFilter);
  }

  if (formConfig.showProperties) {
    const appliedCropZoneFilter = basicIsFilter(
      tableData.tableName,
      tableData.cropZoneIdColumnName,
      [...new Set(values.selectedCropzones.map(({ id }) => id))]?.join(',')
    );
    filters.push(appliedCropZoneFilter);
  }

  if (formConfig.showWorkOrders) {
    const appliedWorkOrderFilter = basicIsFilter(
      tableData.tableName,
      tableData.taskIdColumnName,
      values.selectedWorkOrders?.join(',')
    );
    filters.push(appliedWorkOrderFilter);
  }

  if (formConfig.showProducts) {
    const appliedProductFilter = basicIsFilter(
      tableData.tableName,
      tableData.productIdColumnName,
      values.selectedProducts?.join(',')
    );
    filters.push(appliedProductFilter);
  }

  if (formConfig.showMapStyle) {
    const appliedMapStyleFilter = basicIsFilter(
      tableData.tableName,
      tableData.mapStyleColumnName,
      values.selectedMapStyle
    );

    filters.push(appliedMapStyleFilter);
  }

  return filters;
};
