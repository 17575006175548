import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useCropSeasons from 'hooks/useCropSeasons';
import ItemsDropdown from 'components/ItemsDropdown';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import SeasonCreateModal from 'components/Modals/SeasonCreateModal';

import CompanyPeopleDropDownItem from './components/CropSeasonSingleSelectionDropdownItem';

const CropSeasonSingleSelectionDropdown = ({
  onChange,
  value,
  id,
  createNewCropSeasonEnabled
}) => {
  const [openCropSeasonNewModal, setOpenCropSeasonNewModal] = useState(false);
  const amplitude = useContext(AmplitudeContext);

  const [categorizedCropSeasons, setCategorizedCropSeasons] = useState({
    active: [],
    upcoming: [],
    past: []
  });

  const [mixedItems, setMixedItems] = useState([]);

  const { cropSeasons, loaded } = useCropSeasons();

  const handleSeasonType = season => {
    const currentTime = new Date().getTime();
    if (currentTime > season.startDateEpoch) {
      if (currentTime > season.endDateEpoch) {
        return 'Past';
      }
      return 'Active';
    }
    return 'Upcoming';
  };

  useEffect(() => {
    // To Categorize CropSeasons
    if (loaded && cropSeasons?.length > 0) {
      const categorizedCropSeasonData = { active: [], upcoming: [], past: [] };
      cropSeasons.forEach(item => {
        const type = handleSeasonType(item);
        switch (type) {
          case 'Active':
            categorizedCropSeasonData.active.push(item);
            break;
          case 'Upcoming':
            categorizedCropSeasonData.upcoming.push(item);
            break;
          case 'Past':
            categorizedCropSeasonData.past.push(item);
            break;
          default:
            break;
        }
      });
      setCategorizedCropSeasons({ ...categorizedCropSeasonData });
    }
  }, [loaded, cropSeasons]);

  const valueRenderer = useCallback(() => {
    if (!value?.type) {
      return null;
    }
    return (
      <div
        className="w-full h-7 p-2 flex items-center text-base"
        data-testid="selected-crop-season"
      >
        <div className="pl-2">{value.name}</div>
      </div>
    );
  }, [value]);

  useEffect(() => {
    if (categorizedCropSeasons) {
      setMixedItems([
        {
          key: 'active-seasons',
          label: 'Active Seasons',
          type: 'Active',
          items:
            categorizedCropSeasons.active.length > 0
              ? categorizedCropSeasons.active.map(season => {
                  return {
                    key: season.id,
                    label: season.name,
                    value: { ...season, type: 'Active' }
                  };
                })
              : [
                  {
                    key: 'empty-active',
                    label: 'empty',
                    type: 'Active'
                  }
                ]
        },
        {
          key: 'upcoming-seasons',
          label: 'Upcoming Seasons',
          type: 'Upcoming',
          items:
            categorizedCropSeasons.upcoming.length > 0
              ? categorizedCropSeasons.upcoming.map(season => {
                  return {
                    key: season.id,
                    label: season.name,
                    value: { ...season, type: 'Upcoming' }
                  };
                })
              : [
                  {
                    key: 'empty-upcoming',
                    label: 'empty',
                    type: 'Upcoming'
                  }
                ]
        },
        {
          key: 'past-seasons',
          label: 'Past Seasons',
          type: 'Past',
          items:
            categorizedCropSeasons.past.length > 0
              ? categorizedCropSeasons.past.map(season => {
                  return {
                    key: season.id,
                    label: season.name,
                    value: { ...season, type: 'Past' }
                  };
                })
              : [
                  {
                    key: 'empty-past',
                    label: 'empty',
                    type: 'Past'
                  }
                ]
        }
      ]);
    }
    return () => {
      setMixedItems([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorizedCropSeasons]);

  const handleCreateSeason = async season => {
    onChange({ ...season, type: handleSeasonType(season) });
  };

  return (
    <>
      <ItemsDropdown
        rightOffset={0}
        heightClass="h-64"
        mainClass="items-drop-down-field-1"
        valueRenderer={valueRenderer}
        value={value}
        onChange={onChange}
        id={id}
        items={mixedItems}
        ItemComponent={CompanyPeopleDropDownItem}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(createNewCropSeasonEnabled && {
          onBottomButtonClick: () => {
            setOpenCropSeasonNewModal(true);
          },
          bottomButtonText: 'Create New Crop Season'
        })}
      />
      {createNewCropSeasonEnabled && (
        <SeasonCreateModal
          open={openCropSeasonNewModal}
          creationFromPage={
            amplitude.events.epic.CropSeason.pages.agVendIntegrationPage
          }
          onClose={() => setOpenCropSeasonNewModal(false)}
          onSuccess={handleCreateSeason}
        />
      )}
    </>
  );
};

CropSeasonSingleSelectionDropdown.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  createNewCropSeasonEnabled: PropTypes.bool
};

CropSeasonSingleSelectionDropdown.defaultProps = {
  value: null,
  id: '',
  createNewCropSeasonEnabled: false
};

export default CropSeasonSingleSelectionDropdown;
