const getAllCoordinates = (property, farm) => {
  if (property?.geometry) {
    if (property.geometry.type === 'Polygon') {
      return property.geometry.coordinates[0];
    }

    if (property.geometry.type === 'MultiPolygon') {
      return property.geometry.coordinates.flatMap(polygon => polygon[0]);
    }

    if (property.geometry.type === 'Point') {
      return [property.geometry.coordinates];
    }
  }

  return [
    ...(property?.fields?.flatMap(field =>
      field?.geometry?.coordinates.flat()
    ) || []),
    ...(farm?.fields?.flatMap(field => field?.geometry?.coordinates.flat()) ||
      [])
  ];
};

export default getAllCoordinates;
