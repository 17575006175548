/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-unused-vars
export const urlGenerator = (org, callbackURI) => {
  let env;
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      env = 'qa.';
      break;
    case 'qa':
      env = 'qa.';
      break;
    case 'staging':
      env = 'qa.';
      break;
    default:
      env = '';
  }
  const urlObjects = {
    johnDeere: {
      url: `https://${env}connect.cropwise.com/integrations`
    }
  };

  return urlObjects;
};
