import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { Search } from '@agconnections/grow-ui';

import useCropData from 'hooks/useCropData';
import GrowUIFormField from 'components/Forms/GrowUIFormField';

export const onSelect = (
  { key, value },
  { values, touched, setFieldValue, setFieldTouched }
) => {
  let isCropTouched = !!touched.crop;
  setFieldValue('crop', key);

  if (key !== values.crop) {
    isCropTouched = true;
  }

  if (isCropTouched) {
    setFieldValue('name', value);
  }

  setFieldTouched('crop', isCropTouched);
};

const CropZoneCropSelect = () => {
  const { crops, fetchCrops } = useCropData();

  const formikContext = useFormikContext();

  const cropItems = useMemo(
    () =>
      crops?.map(crop => ({
        key: crop.id,
        value: crop.name
      })) || [],
    [crops]
  );

  const onCropSelect = ({ key, value }) => {
    onSelect({ key, value }, formikContext);
  };

  useEffect(() => {
    fetchCrops();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikContext.values.crop]);

  return (
    <div className="-mt-8">
      <label
        htmlFor="crop"
        className="text-neutral-600 text-sm inline-block mb-1 "
      >
        Crop*
      </label>
      <GrowUIFormField
        name="crop"
        items={cropItems}
        control={Search}
        placeholder="Select Crop"
        onSelect={onCropSelect}
      />
    </div>
  );
};

export default CropZoneCropSelect;
