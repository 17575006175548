import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, Field } from 'formik';
import { Input } from 'syngenta-digital-cropwise-react-ui-kit';
import { DEFAULT_ROUND, Maths } from 'helpers/maths';
import formatNumber from 'helpers/formatNumber';
import Decimal from 'decimal.js';

function debounce(func, wait) {
  let timeout;
  // eslint-disable-next-line func-names
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

const FormInputWithPrecisionDisplay = ({
  object,
  fieldName,
  type,
  isRequired,
  precision,
  inputSuffix,
  id,
  inputPrefix,
  onControlledChange,
  disabled,
  round
}) => {
  let {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    validateField
  } = { values: [], touched: [], errors: [] };
  const formikContext = useFormikContext();
  if (!object && formikContext) {
    values = formikContext.values;
    touched = formikContext.touched;
    errors = formikContext.errors;
    setFieldValue = formikContext.setFieldValue;
    setFieldTouched = formikContext.setFieldTouched;
    validateField = formikContext.validateField;
  }

  const error = errors[fieldName];
  const [fieldValueOnFocus, setFieldValueOnFocus] = useState(0);
  const [displayValue, setDisplayValue] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  function formatCurrency(num) {
    const regex = /(\d)(?=(\d{3})+(?!\d))/g;
    if (num?.toString().includes('.')) {
      const [integerPart, decimalPart] = num.toString().split('.');
      const integerPartWithCommas = integerPart.replace(regex, '$1,');
      return `${integerPartWithCommas}.${decimalPart ?? ''}`;
    }
    return num?.toString()?.replace(regex, '$1,') ?? '';
  }

  const updateDisplayValue = useCallback(
    value => {
      if (error) {
        setDisplayValue(value);
        return;
      }
      const roundedValue = Maths.parseFloatRound(
        value?.toString()?.replace(/[^\d.]/g, ''),
        precision,
        Decimal.ROUND_HALF_UP
      )?.toFixed(precision);
      const isNonValidValue = isNaN(roundedValue);
      const valueRounded = isNonValidValue ? value : roundedValue;
      setDisplayValue(
        formatNumber(valueRounded, '', precision, Decimal.ROUND_HALF_UP)
      );
    },
    [precision, error]
  );

  useEffect(() => {
    const value = object ? object[fieldName] : values[fieldName];
    setFieldValueOnFocus(formatCurrency(value));
  }, [object, values, fieldName, updateDisplayValue, isEditing]);

  useEffect(() => {
    if (!isEditing) {
      updateDisplayValue(fieldValueOnFocus);
    }
  }, [isEditing, fieldValueOnFocus, updateDisplayValue]);

  const handleChange = field => event => {
    const { value, selectionStart } = event.target;
    const element = event.target;
    const sanitizedValue = value
      .replace(/[^0-9.]/g, '')
      .replace(/\.(?=.*\.)/g, '');
    if (object) {
      // eslint-disable-next-line no-param-reassign
      object[field] = sanitizedValue;
      updateDisplayValue(value);
    } else {
      setFieldTouched(fieldName, true);
      setFieldValue(field, sanitizedValue).then(() => {
        validateField(field);
        updateDisplayValue(value);
      });
    }
    const formattedValue = formatCurrency(sanitizedValue);

    setFieldValueOnFocus(formattedValue);
    window.requestAnimationFrame(() => {
      // compare the formatted value with the value in the input in amount of commas
      // if the formatted value has more commas than the value in the input
      // it means that the user has typed a comma
      // and we should move the cursor to the right
      // if it is equal, it means that we need to keep the cursor in the same position
      // otherwise, we should move the cursor to the left
      const commasInFormattedValue = (formattedValue.match(/,/g) || []).length;
      const commasInValue = (value.match(/,/g) || []).length;
      let newSelectionStart;
      if (commasInFormattedValue > commasInValue) {
        newSelectionStart = selectionStart + 1;
      } else if (commasInFormattedValue < commasInValue) {
        newSelectionStart = selectionStart - 1;
      } else {
        newSelectionStart = selectionStart;
      }
      element.selectionStart = newSelectionStart;
      element.selectionEnd = newSelectionStart;
    });

    if (onControlledChange) onControlledChange(event);
  };

  const handleBlur = debounce(() => {
    setIsEditing(false);
    if (!errors[fieldName]) {
      const updatedValue = Maths.parseFloatRound(
        fieldValueOnFocus?.toString()?.replace(/[^\d.]/g, ''),
        round,
        Decimal.ROUND_HALF_UP
      );
      const updatedValueSanitized = isNaN(updatedValue) ? '' : updatedValue;
      if (object) {
        // eslint-disable-next-line no-param-reassign
        object[fieldName] = updatedValueSanitized;
        updateDisplayValue(fieldValueOnFocus);
      } else {
        setFieldValue(fieldName, updatedValueSanitized).then(() => {
          validateField(fieldName);
          updateDisplayValue(fieldValueOnFocus);
        });
        // if there are errors with the field,
        // we should not set it as untouched,
        // otherswise the error message
        // will not be displayed
        setFieldTouched(fieldName, false);
      }
    }
  }, 300);

  const handleFocus = () => {
    setIsEditing(true);
  };

  const innerInput = (
    <Input
      data-testid={`${fieldName}-input`}
      id={id}
      disabled={disabled}
      prefix={inputPrefix}
      suffix={inputSuffix}
      size="middle"
      type={type}
      required={isRequired}
      className={
        errors[fieldName] && touched[fieldName] ? 'border border-remove' : ''
      }
      value={isEditing ? fieldValueOnFocus : displayValue}
      onChange={handleChange(fieldName)}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );

  return object ? (
    innerInput
  ) : (
    <Field name={fieldName}>
      {() => {
        return innerInput;
      }}
    </Field>
  );
};

FormInputWithPrecisionDisplay.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  precision: PropTypes.number,
  inputSuffix: PropTypes.node,
  inputPrefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  type: PropTypes.string,
  onControlledChange: PropTypes.func,
  round: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  object: PropTypes.object,
  disabled: PropTypes.bool
};

FormInputWithPrecisionDisplay.defaultProps = {
  object: null,
  isRequired: false,
  precision: 2,
  inputSuffix: null,
  inputPrefix: '',
  id: '',
  type: 'text',
  onControlledChange: null,
  round: DEFAULT_ROUND,
  disabled: false
};

export default FormInputWithPrecisionDisplay;
