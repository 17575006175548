import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'components/Store';
import MassAssignerTree from './components/MassAssignerTree';
import DefaultTree from './components/DefaultTree';
import PropertyLandingContext from '../../context';
import ExportPDFMapsOptionsForm from './components/ExportPDFMapsOptionsForm';
import SearchLoading from './components/SearchLoading';

const PropertyTreeView = ({ properties, rawProperties }) => {
  const {
    isHugeOrganization,
    fieldsAndAreasGeoJSONCollection,
    isLoadingPaginatedProperties
  } = useContext(PropertyLandingContext);
  const [
    {
      isMassAssignerActive,
      fieldsToMassiveAssign,
      isExportPDFMapsActive,
      exportPDFMapsStep,
      searchTextValueFFT: searchTerm
    }
  ] = useContext(Context);

  const propertiesFilter = useCallback(
    (obj, searchTextValueFFT) => {
      if (isHugeOrganization) return obj;
      return searchTextValueFFT.valueInput === '' ||
        searchTextValueFFT.valueInput === undefined
        ? obj
        : obj.filter(
            prop =>
              prop.name.toLowerCase().includes(searchTextValueFFT.valueInput) ||
              prop.fields.some(field =>
                field.name.toLowerCase().includes(searchTextValueFFT.valueInput)
              )
          );
    },
    [isHugeOrganization]
  );

  if (isLoadingPaginatedProperties && searchTerm?.valueInput) {
    return <SearchLoading />;
  }

  if (
    isMassAssignerActive ||
    (isExportPDFMapsActive && exportPDFMapsStep === 0)
  ) {
    return (
      <MassAssignerTree
        properties={properties}
        fieldsAndAreasGeoJSONCollection={fieldsAndAreasGeoJSONCollection}
        propertiesFilter={propertiesFilter}
      />
    );
  }
  if (isExportPDFMapsActive && exportPDFMapsStep === 1) {
    return <ExportPDFMapsOptionsForm selectedFields={fieldsToMassiveAssign} />;
  }
  return (
    <DefaultTree
      properties={properties}
      rawProperties={rawProperties}
      propertiesFilter={propertiesFilter}
    />
  );
};

PropertyTreeView.defaultProps = {
  properties: [],
  rawProperties: []
};

PropertyTreeView.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object),
  rawProperties: PropTypes.arrayOf(PropTypes.object)
};

export default PropertyTreeView;
