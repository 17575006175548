import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { format } from 'date-fns';
import { generate } from 'shortid';

import { ReactComponent as DownloadIcon } from 'assets/download_outlined_green.svg';
import { Context } from 'components/Store';
import { getFieldCrops } from 'screens/Property/helpers/propertyDataHelpers';
import { scaleToMapPDF as scale } from 'screens/Property/helpers/mapApiHelpers';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import CropLegend, { renderCropLegendRows } from '../CropLegend';
import renderLegendLabelRows from './LegendLabelRows';
import PDFPage, {
  MAP_PREVIEW_ID,
  LEGEND_PREVIEW_ID,
  MAP_EXPORTABLE_ID,
  LEGEND_EXPORTABLE_ID
} from './PDFPage';

const CROP_LEGEND_ROWS_PER_PAGE = 28;
const LEGEND_LABEL_ROWS_PER_PAGE = 20;
const CANVAS_SCALE = 4;
const PAGE_WIDTH = 792; // 11 inches * 72 DPI
const PAGE_HEIGHT = 612; // 8.5 inches * 72 DPI

const WithExportPreview = ({ fields, children }) => {
  const amplitude = useContext(AmplitudeContext);
  const [
    {
      isExportPDFMapsActive,
      exportPDFMapsStep,
      fieldsToMassiveAssign,
      cropColors,
      mapLabelConfig,
      mapLegendLabels
    }
  ] = useContext(Context);
  const [containerWidth, setContainerWidth] = useState(792);
  const [containerHeight, setContainerHeight] = useState(612);
  const containerRef = useRef(null);

  const updateContainerDimensions = () => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
      setContainerHeight(containerRef.current.offsetHeight);
    }
  };

  useLayoutEffect(() => {
    updateContainerDimensions();
    window.addEventListener('resize', updateContainerDimensions);

    return () => {
      updateContainerDimensions();
      window.removeEventListener('resize', updateContainerDimensions);
    };
  }, [exportPDFMapsStep]);

  const [isExporting, setIsExporting] = useState(false);

  const separateLegend = useMemo(
    () => mapLabelConfig.labelLocation === 'legend',
    [mapLabelConfig]
  );
  const showCropLegend =
    !!fields.find(({ cropzones }) => cropzones.length) && !separateLegend;
  const crops = getFieldCrops(fields);

  const cropLegendPages = useMemo(() => {
    if (!separateLegend) return [];

    const _cropLegendRows = renderCropLegendRows({
      crops,
      cropColors,
      size: 'large'
    });

    return _cropLegendRows.reduce((acc, _, i) => {
      if (i % CROP_LEGEND_ROWS_PER_PAGE === 0) {
        acc.push(_cropLegendRows.slice(i, i + CROP_LEGEND_ROWS_PER_PAGE));
      }
      return acc;
    }, []);
  }, [separateLegend, crops, cropColors]);

  const legendLabelRows = separateLegend
    ? renderLegendLabelRows({
        fieldsToMassiveAssign,
        mapLegendLabels
      })
    : [];

  const numLegendLabelPages = Math.ceil(
    legendLabelRows.length / LEGEND_LABEL_ROWS_PER_PAGE
  );

  const handleDownloadPDF = () => {
    setIsExporting(true);
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Properties.selectExportOption
    );
  };

  useEffect(() => {
    if (!isExporting) return;

    async function exportPDF() {
      const style = document.createElement('style');
      document.head.appendChild(style);

      if (style.sheet) {
        style.sheet.insertRule(
          'body > div:last-child img { display: inline-block; }'
        );
      }

      let input = document.getElementById(MAP_EXPORTABLE_ID);

      let canvas = await html2canvas(input, {
        scale: CANVAS_SCALE,
        useCORS: true,
        letterRendering: true
      });
      const pdf = new JsPDF({
        orientation: 'landscape',
        unit: 'pt',
        format: [PAGE_WIDTH, PAGE_HEIGHT]
      });

      const addPageToPDF = async id => {
        document.head.appendChild(style);

        if (style.sheet) {
          style.sheet.insertRule(
            'body > div:last-child img { display: inline-block; }'
          );
        }
        input = document.getElementById(id);
        canvas = await html2canvas(input, {
          scale: CANVAS_SCALE,
          useCORS: true,
          letterRendering: true
        });

        const imgData = canvas.toDataURL('image/png');
        if (id !== MAP_EXPORTABLE_ID) pdf.addPage();

        const imgWidth = canvas.width / CANVAS_SCALE;
        const imgHeight = canvas.height / CANVAS_SCALE;
        const ratio = Math.min(PAGE_WIDTH / imgWidth, PAGE_HEIGHT / imgHeight);
        const scaledWidth = imgWidth * ratio;
        const scaledHeight = imgHeight * ratio;
        const xOffset = (PAGE_WIDTH - scaledWidth) / 2;
        const yOffset = (PAGE_HEIGHT - scaledHeight) / 2;
        pdf.addImage(
          imgData,
          'PNG',
          xOffset,
          yOffset,
          scaledWidth,
          scaledHeight
        );
      };

      await addPageToPDF(MAP_EXPORTABLE_ID);

      // Start at page 2, then add each legend page (property labels and crops)
      if (separateLegend) {
        for (
          let i = 2;
          i < numLegendLabelPages + cropLegendPages.length + 2;
          i += 1
        ) {
          // eslint-disable-next-line no-await-in-loop
          await addPageToPDF(`${LEGEND_EXPORTABLE_ID}_${i}`);
        }
      }

      const fileName = `Map_${format(new Date(), 'yyyy-MM-dd-HHmm')}.pdf`;
      pdf.save(fileName);
      setIsExporting(false);

      // Remove the style element after the PDF is created
      style.remove();
    }
    exportPDF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExporting]);

  if (!isExportPDFMapsActive || exportPDFMapsStep !== 1) {
    return <>{children}</>;
  }

  // Adjust for 8px padding on either side, divide by default dimension size
  const scaleWidth = (containerWidth - 16) / scale(792);
  const scaleHeight = (containerHeight - 16) / scale(612);
  const pageScale = Math.min(scaleWidth, scaleHeight);

  const generatePDFPages = ({ isExportable }) => {
    const staticStyles = {
      transform: `scale(${pageScale})`,
      transformOrigin: 'top center',
      zIndex: 1,
      position: 'static'
    };
    const absoluteStyles = {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: -1
    };
    const style = isExportable ? absoluteStyles : staticStyles;

    const mapPageId = isExportable ? MAP_EXPORTABLE_ID : MAP_PREVIEW_ID;
    const legendPageId = isExportable
      ? LEGEND_EXPORTABLE_ID
      : LEGEND_PREVIEW_ID;

    return (
      <div style={style}>
        <PDFPage
          id={mapPageId}
          pageNo={separateLegend ? 1 : undefined}
          mapLegend={
            <CropLegend
              crops={crops}
              cropColors={cropColors}
              show={showCropLegend}
              style={{
                bottom: scale(29),
                right: scale(9)
              }}
              limit={25}
            />
          }
        >
          {children}
        </PDFPage>
        {separateLegend && (
          <>
            {Array.from({ length: numLegendLabelPages }).map((_, pageNo) => (
              <PDFPage
                pageNo={pageNo + 2}
                id={`${legendPageId}_${pageNo + 2}`}
                key={generate()}
              >
                <Row
                  className="font-semibold"
                  style={{ fontSize: `${scale(14)}px` }}
                >
                  Map Legend
                </Row>
                <Row
                  className="font-semibold"
                  style={{
                    fontSize: scale(12),
                    marginTop: scale(8)
                  }}
                >
                  Properties
                </Row>
                {legendLabelRows.slice(
                  pageNo * LEGEND_LABEL_ROWS_PER_PAGE,
                  (pageNo + 1) * LEGEND_LABEL_ROWS_PER_PAGE
                )}
              </PDFPage>
            ))}
            {cropLegendPages?.map((cropLegendRows, i) => (
              <PDFPage
                pageNo={i + numLegendLabelPages + 2}
                id={`${legendPageId}_${i + numLegendLabelPages + 2}`}
                key={generate()}
              >
                <Row className="font-semibold">Map Legend</Row>
                <Row
                  className="font-semibold"
                  style={{
                    fontSize: scale(12),
                    marginTop: scale(16),
                    marginBottom: scale(4)
                  }}
                >
                  Crops
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    {cropLegendRows
                      .slice(0, Math.ceil(CROP_LEGEND_ROWS_PER_PAGE / 2))
                      .map(row => (
                        <div key={generate()}>{row}</div>
                      ))}
                  </Col>
                  <Col span={12} className="-mt-1">
                    {cropLegendRows
                      .slice(Math.ceil(CROP_LEGEND_ROWS_PER_PAGE / 2))
                      .map(row => (
                        <div key={generate()}>{row}</div>
                      ))}
                  </Col>
                </Row>
              </PDFPage>
            ))}
          </>
        )}
      </div>
    );
  };

  return (
    <div
      ref={containerRef}
      className="flex flex-col flex-grow items-center pt-2"
      style={{ backgroundColor: '#f2f4f6', position: 'relative' }}
    >
      {generatePDFPages({ isExportable: false })}
      {generatePDFPages({ isExportable: true })}
      <div
        className="fixed z-1 flex justify-center p-4 bg-white rounded-lg shadow-sm-card"
        style={{ bottom: 24 }}
      >
        <Row gutter={8} justify="center">
          {/* To implement in future stories */}
          {/* <Col>
            <Button type={ButtonType.primary} ghost size="large">
              Email
            </Button>
          </Col>
          <Col>
            <Button type={ButtonType.primary} ghost size="large">
              Print
            </Button>
          </Col> */}
          <Col>
            <Button
              type={ButtonType.primary}
              ghost
              size="large"
              onClick={handleDownloadPDF}
            >
              <Row gutter={4} align="middle">
                <Col>
                  <DownloadIcon />
                </Col>
                <Col>Download PDF</Col>
              </Row>
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

WithExportPreview.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      area: PropTypes.number.isRequired
    })
  ).isRequired,
  children: PropTypes.node.isRequired
};

export default WithExportPreview;
