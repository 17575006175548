/**
 * Matches the selected cropzones id with properties data
 *
 * @param {Array} propertiesData - List of properties, each containing fields and cropzones.
 * @param {Array} selectedIds - List of selected cropzone IDs.
 * @param {Array} geoJSONFeatures - List of GeoJSON features for geometry data.
 * @returns {Array} - Array of selected cropzones with additional data like farmId, fieldName, and matched features.
 */
const getSelectedCropzones = (
  propertiesData = [],
  selectedIds = [],
  geoJSONFeatures = []
) => {
  if (!propertiesData?.length) {
    return [];
  }

  const getCropzoneFeatures = fieldId =>
    geoJSONFeatures.filter(feature => fieldId === feature.properties.id);

  const mapCropzones = (cropzones, farmId, fieldName, fieldId) =>
    cropzones
      .filter(({ id }) => selectedIds.includes(id))
      .map(cz => ({
        ...cz,
        farmId,
        fieldName,
        features: getCropzoneFeatures(fieldId)
      }));

  const mapFields = (fields, farmId) =>
    fields.flatMap(({ id: fieldId, name: fieldName, cropzones = [] }) =>
      mapCropzones(cropzones, farmId, fieldName, fieldId)
    );

  return propertiesData.flatMap(({ id: farmId, fields = [] }) =>
    mapFields(fields, farmId)
  );
};

export default getSelectedCropzones;
