import React from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from '@agconnections/grow-ui';

import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import PropertyMapWrapper from '../PropertyLandingMap/PropertyMapWrapper';
import CropZoneCard from './CropZoneCard';
import useCropZoneFeatures from './hooks/useCropZoneFeatures';

const CropZoneCreate = () => {
  const { fieldId, action, cropzoneId } = useParams();
  const breadcrumbTitle = action === 'edit' ? 'Edit' : 'New';
  const {
    field,
    selectedFeatures,
    setIsSaving,
    cropzone,
    isLoading,
    isSaving,
    mapCoordinates,
    zoom,
    setZoom,
    selectedFeatureIds,
    initialFeatures,
    handleSelectedFeatures,
    handleFeaturesChange,
    setSelectedFeatures
  } = useCropZoneFeatures(fieldId, cropzoneId);

  return (
    <>
      <Breadcrumb disabled>
        <Breadcrumb.Item
          title="Properties"
          value={`${breadcrumbTitle} Crop Zone`}
          isLast
        />
      </Breadcrumb>
      <div className="flex h-full w-full">
        <div className="flex flex-col w-1/3 h-auto m-4">
          <CropZoneCard
            field={field}
            features={selectedFeatures}
            isSaving={setIsSaving}
            cropzone={action === 'edit' ? cropzone : {}}
          />
        </div>
        <div className="relative w-full" id="map-wrapper-container">
          {isLoading || isSaving || !mapCoordinates ? (
            <Spinner />
          ) : (
            <PropertyMapWrapper
              zoom={zoom}
              setZoom={setZoom}
              currentCoordinates={mapCoordinates}
              shapeIdSelected={selectedFeatureIds}
              geoJSONCollection={initialFeatures}
              onSelectFeatures={handleSelectedFeatures}
              onFeaturesChange={handleFeaturesChange}
              setNewFieldFeatures={setSelectedFeatures}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CropZoneCreate;
