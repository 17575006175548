import * as Yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const AddMasterListProductSchema = Yup.object().shape({
  averagePricePerUnit: Yup.number()
    .typeError('List Price must be a number')
    .min(0, 'List Price must be a positive number')
    .notRequired(),
  packageWeight: Yup.number()
    .notOneOf([0], 'Weight cannot be 0')
    .positive('Weight must be a positive number')
    .typeError('Weight must be a number')
});
