const sortByName = (a, b) => {
  if (!a?.name) return 1;
  if (!b?.name) return -1;
  return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
};

const sortTree = data => {
  const treeData = { ...data };
  const tree = treeData.properties;
  if (tree.length) {
    treeData.properties
      .sort(sortByName)
      .forEach(({ fields }) =>
        fields
          .sort(sortByName)
          .forEach(({ cropzones }) => cropzones.sort(sortByName))
      );

    return treeData;
  }
  return treeData;
};
export default sortTree;
