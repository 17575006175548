import React from 'react';
import Spin from '../../../../../../../assets/spinner_modal.svg';

const SearchLoading = () => {
  return (
    <div className="w-full h-full mt-12">
      <div className="flex flex-col w-full items-center">
        <img src={Spin} alt="spinner" className="animate-spin m-auto" />
        <p className="font-normal text-normal mt-5">Loading results...</p>
      </div>
    </div>
  );
};

export default SearchLoading;
