import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, Field } from 'formik';
import { Input } from 'syngenta-digital-cropwise-react-ui-kit';
import { DEFAULT_ROUND, Maths } from 'helpers/maths';
import './antd-override.css';

function debounce(func, wait) {
  let timeout;
  // eslint-disable-next-line func-names
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

/**
 * All numbers should be rounded to the second decimal point when they are displayed to users
 * Users can enter as many decimal points on numeric or text inputs up to 15 places, this rounding only happens on numbers that are displayed to the user.
 * https://digitial-product-engineering.atlassian.net/wiki/spaces/LDBAPP/pages/3152609282/Product+Design+Handoff+Guide+Standards#8.-Number-Formatting-(Decimals-and-Commas)
 */
const FormInputWithPrecisionDisplay = ({
  fieldName,
  type,
  isDisabled,
  isRequired,
  precision,
  inputSuffix,
  id,
  inputPrefix,
  onControlledChange,
  round
}) => {
  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    validateField
  } = useFormikContext();
  const formValue = values[fieldName];
  const error = errors[fieldName];
  const [displayValue, setDisplayValue] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const updateDisplayValue = useCallback(
    value => {
      if (error) {
        setDisplayValue(value);
        return;
      }
      const roundedValue = Maths.parseFloatRound(value, precision)?.toFixed(
        precision
      );
      const isNonValidValue = isNaN(roundedValue);
      const valueRounded = isNonValidValue ? value : roundedValue;
      setDisplayValue(valueRounded);
    },
    [precision, error]
  );

  useEffect(() => {
    if (!isEditing) {
      updateDisplayValue(formValue);
    }
  }, [formValue, isEditing, updateDisplayValue]);

  const handleChange = field => event => {
    const { value } = event.target;
    setFieldTouched(fieldName, true);
    setFieldValue(field, value).then(() => {
      validateField(field);
      updateDisplayValue(value);
    });
    if (onControlledChange) onControlledChange(event);
  };

  const handleBlur = debounce(() => {
    setIsEditing(false);
    if (!errors[fieldName]) {
      const updatedValue = Maths.parseFloatRound(formValue, round);
      setFieldValue(fieldName, isNaN(updatedValue) ? '' : updatedValue).then(
        () => {
          validateField(fieldName);
          updateDisplayValue(formValue);
        }
      );
      // if there are errors with the field,
      // we should not set it as untouched,
      // otherswise the error message
      // will not be displayed
      setFieldTouched(fieldName, false);
    }
  }, 50);

  const handleFocus = () => {
    setIsEditing(true);
  };

  return (
    <Field name={fieldName}>
      {() => (
        <Input
          data-testid={`${fieldName}-input`}
          id={id}
          disabled={isDisabled}
          prefix={inputPrefix}
          suffix={inputSuffix}
          size="middle"
          type={type}
          required={isRequired}
          className={
            errors[fieldName] && touched[fieldName]
              ? 'border border-remove'
              : ''
          }
          value={isEditing ? formValue : displayValue}
          onChange={handleChange(fieldName)}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      )}
    </Field>
  );
};

FormInputWithPrecisionDisplay.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  precision: PropTypes.number,
  inputSuffix: PropTypes.node,
  inputPrefix: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  onControlledChange: PropTypes.func,
  round: PropTypes.number
};

FormInputWithPrecisionDisplay.defaultProps = {
  isDisabled: false,
  isRequired: false,
  precision: 2,
  inputSuffix: null,
  inputPrefix: '',
  id: '',
  type: 'text',
  onControlledChange: null,
  round: DEFAULT_ROUND
};

export default FormInputWithPrecisionDisplay;
