import React, { useEffect, useState } from 'react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { Formik } from 'formik';
import { Button } from '@agconnections/grow-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';

import DownloadOutlined from 'assets/download_outlined.svg';
import DownloadState from 'assets/download_state.svg';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { Context } from 'components/Store';
import SpinnerModal from 'components/SpinnerModal';
import Toast from 'components/Toast';
import ReportsProvider from './context/ReportsProvider';
import SideMenu from './components/SideMenu';
import useReport from './hooks/useReport';
import useExportReport from './hooks/useExportReport';
import initialFormValues from './helpers/initialFormValues';
import applyReportFilters from './helpers/applyReportFilters';
import formTouched from './helpers/formTouched';
import ReportDetailsForm from './components/ReportDetailsForm';
import './index.css';

const Reports = () => {
  const { releaseFeReportsTimezoneOffsetFormat } = useFlags();

  const [powerBIStates, setPowerBIStates] = useState({});
  const [activeReport, setActiveReport] = useState();
  const [submittedValues, setSubmittedValues] = useState(initialFormValues);
  const [exportPollingInterval, setExportPollingInterval] = useState();
  const [showExportSuccessToast, setShowExportSuccessToast] = useState(false);
  const [showExportErrorToast, setShowExportErrorToast] = useState(false);

  const {
    loadEmbeddedReport,
    embeddedReport,
    embedToken,
    setEmbedToken,
    setReportState
  } = useReport();

  const {
    exportReportToPDF,
    pollExportJob,
    getExportedReport,
    setAccessToken: setExportAccessToken,
    exportJob
  } = useExportReport();

  const orgId = Context?._currentValue[0]?.organization?.id;

  const handleSideMenuChange = () => {
    setEmbedToken();
  };

  const exportOnly = false;
  // To add back when export to XLSX API is viable
  // activeReport?.category === ReportCategories.Excel &&
  // activeReport?.subType === ReportSubTypes.ExcelReport;

  const handleExportClick = () => {
    exportReportToPDF(activeReport, powerBIStates[activeReport?.name].filters);
  };

  const handleSubmit = values => {
    if (!activeReport || !powerBIStates[activeReport?.name]) return;

    const filters = applyReportFilters(
      activeReport.formConfig,
      orgId,
      values,
      releaseFeReportsTimezoneOffsetFormat
    );

    setPowerBIStates(prev => ({
      ...prev,
      [activeReport?.name]: {
        ...prev[activeReport?.name],
        filters
      }
    }));

    setSubmittedValues(values);
  };

  useEffect(() => {
    if (activeReport) {
      setReportState(activeReport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeReport]);

  useEffect(() => {
    loadEmbeddedReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseFeReportsTimezoneOffsetFormat]);

  useEffect(() => {
    if (!embedToken || !embeddedReport) {
      return;
    }

    setExportAccessToken(embeddedReport.accessToken);
    setPowerBIStates(prevState => ({
      ...prevState,
      [activeReport?.name]: {
        type: 'report',
        id: embeddedReport.id,
        name: embeddedReport.name,
        accessToken: embedToken,
        tokenType: models.TokenType.Embed,
        embedUrl: embeddedReport.embedUrl,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false
            }
          }
        }
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embedToken, embeddedReport]);

  const isExportLoading = () =>
    exportJob.status === 'NotStarted' || exportJob.status === 'Running';

  useEffect(() => {
    if (!exportJob.status) return;

    if (!isExportLoading() && exportPollingInterval) {
      clearInterval(exportPollingInterval);
      setExportPollingInterval();
    }

    if (exportJob.status === 'NotStarted' && !exportPollingInterval) {
      const pollingInterval = setInterval(() => {
        pollExportJob();
      }, 5000);
      setExportPollingInterval(pollingInterval);
    }

    // Export successful
    if (exportJob.resourceLocation) {
      getExportedReport(exportJob.resourceLocation);
      setShowExportSuccessToast(true);
    }

    // Export failed
    if (exportJob.error) {
      setShowExportErrorToast(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportJob]);

  return (
    <div className="w-full h-full font-body bg-app-background">
      <Breadcrumb hideCropSeasonDropdown>
        <Breadcrumb.Item title="Reports" value={activeReport?.name} isLast />
      </Breadcrumb>
      <ReportsProvider>
        <Formik initialValues={initialFormValues} onSubmit={handleSubmit}>
          <div className="flex flex-col h-full">
            <div className="flex flex-1 h-full align-items-center">
              <SideMenu
                setActiveReport={setActiveReport}
                onChange={handleSideMenuChange}
              />
              <ReportDetailsForm
                activeReport={activeReport}
                formConfig={activeReport?.formConfig}
                formTouched={values =>
                  formTouched(activeReport?.formConfig, values, submittedValues)
                }
                embedToken={embedToken}
                exportOnly={exportOnly}
              />
              <div className="flex-auto flex flex-col h-full">
                {exportOnly ? (
                  <>
                    <div
                      className="z-0 flex justify-end bg-white h-52px py-10px pr-24px"
                      style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}
                    >
                      <Button
                        type="primary"
                        icon={
                          <img src={DownloadOutlined} alt="export-to-pdf" />
                        }
                        className="ml-auto w-auto"
                        onClick={handleExportClick}
                      >
                        Export Excel
                      </Button>
                    </div>
                    <div className="flex justify-center items-center h-full">
                      <div className="flex flex-col">
                        <img
                          src={DownloadState}
                          alt="download-state"
                          className="mb-12"
                        />
                        <p className="text-sm font-semibold">
                          Click above to download Excel Report
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="pl-16px pr-30px flex-auto h-full">
                    {Object.keys(powerBIStates).map(
                      key =>
                        activeReport?.name === key &&
                        powerBIStates[key].filters?.length && (
                          <PowerBIEmbed
                            key={key}
                            cssClassName=" h-full"
                            embedConfig={powerBIStates[key]}
                          />
                        )
                    )}
                  </div>
                )}
              </div>
            </div>
            <SpinnerModal
              open={exportJob.status && isExportLoading()}
              message="Generating Excel Report"
            />
            <Toast
              type="success"
              title="Excel Report Exported"
              isBold
              open={showExportSuccessToast}
              onClose={() => setShowExportSuccessToast(false)}
              timeout={5000}
            />
            <Toast
              type="error"
              title="Unable to generate Excel"
              isBold
              open={showExportErrorToast}
              onClose={() => setShowExportErrorToast(false)}
              timeout={5000}
            >
              <p className="text-sm font-body">
                There was an issue in downloading the Excel Report, please try
                again. If issue persists contact support.
              </p>
            </Toast>
          </div>
        </Formik>
      </ReportsProvider>
    </div>
  );
};
export default Reports;
