import { useContext, useEffect, useState, useCallback } from 'react';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import PAGINATION_SIZE from 'helpers/globalConstants';
import { Context } from 'components/Store';
import axios from 'axios';
import { CROPWISE_PROXY_URL } from 'utilities/apiConstants';
import { getAccessToken } from 'utilities/base-auth';

const useTasks = (
  { defaultParams, defaultBehavior = true } = {
    defaultParams: {},
    defaultBehavior: true
  }
) => {
  const [{ organization }, dispatch] = useContext(Context);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [request] = useState({
    pageNo: 0,
    size: PAGINATION_SIZE,
    seasonIdsCommaSeparated: JSON.parse(
      localStorage.getItem('selectedCropSeasons')
    ).join(','),
    ...defaultParams
  });

  const headers = {
    'Content-Type': 'application/json',
    'cwf-context': JSON.stringify({
      organization_id: organization.id
    }),
    Authorization: `Bearer ${getAccessToken()}`
  };

  const loadTasks = useCallback(
    params => {
      setLoading(true);

      const source = axios.CancelToken.source();
      const promise = axios.get(`${CROPWISE_PROXY_URL}/v1/base/tasks`, {
        headers,
        params: {
          ...request,
          ...params
        },
        cancelToken: source?.token
      });

      promise
        .then(({ data }) => {
          setTasks(data.results);
        })
        .catch(err => {
          if (!axios.isCancel(err)) {
            catchCancel();
            parseServerError(dispatch)(err);
          }
        })
        .finally(() => setLoading(false));

      return source?.cancel;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [request, headers]
  );

  useEffect(() => {
    if (!defaultBehavior) {
      return;
    }
    const cancel = loadTasks();

    // Cleanup function to cancel the request
    // eslint-disable-next-line consistent-return
    return () => {
      cancel('cancel due to new request');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    tasks,
    loading,
    loadTasks
  };
};

export default useTasks;
