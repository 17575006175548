import {
  booleanEqual,
  getType,
  getCoords,
  polygon,
  centroid,
  feature,
  featureCollection
} from '@turf/turf';
import { cloneDeep, isFunction } from 'lodash';
import sortTree from '../../../helpers/sortTree';
import { NATIONAL_ZOOM, USA_CENTER_COORDINATES } from './constants';
import { formatGeoJSON } from './formatGeoJson';

const setState = (previousValue, newValue) => {
  if (isFunction(newValue)) {
    return newValue(previousValue);
  }
  return newValue;
};
// property data reducer action handlers
export const findMatchingParentPolygonGeometry = (
  parentGeometry,
  childGeometry
) =>
  parentGeometry && childGeometry
    ? getCoords(parentGeometry)?.find(polyonGeometry =>
        booleanEqual(polygon(polyonGeometry), childGeometry)
      )
    : false;
export const findMatchingParentGeometry = (parentGeometry, childGeometry) => {
  if (
    !parentGeometry ||
    !childGeometry ||
    Object.keys(childGeometry).length === 0
  ) {
    return false;
  }
  return getType(parentGeometry) === 'MultiPolygon' &&
    getType(childGeometry) === 'Polygon'
    ? findMatchingParentPolygonGeometry(parentGeometry, childGeometry)
    : booleanEqual(parentGeometry, childGeometry);
};
export const childrenIdsWithIncludedGeometry = (parentGeometry, cropzones) =>
  parentGeometry
    ? (cropzones || [])
        .filter(
          ({ geometry }) =>
            geometry && findMatchingParentGeometry(parentGeometry, geometry)
        )
        .map(({ id }) => id)
    : [];
export const doesParentDuplicateChildGeometry = (
  parentGeometry,
  geometry,
  id
) =>
  parentGeometry && geometry
    ? !!childrenIdsWithIncludedGeometry(parentGeometry, [
        { id, geometry }
      ]).includes(id)
    : false;
export const cropZoneReducer = (propertyId, fieldId, parentGeometry) => (
  acc,
  { geometry, id, ...rest }
) => {
  const cropzoneFeature =
    geometry && Object.keys(geometry).length !== 0
      ? feature(geometry, {
          ...rest,
          id,
          $landType: 'cropzone',
          propertyId,
          $parentFieldId: fieldId,
          $parentDuplicated: doesParentDuplicateChildGeometry(
            parentGeometry,
            geometry,
            id
          )
        })
      : {
          type: 'Feature',
          properties: {
            ...rest,
            id,
            $landType: 'cropzone',
            propertyId,
            $parentFieldId: fieldId,
            $parentDuplicated: false
          }
        };

  return [...acc, cropzoneFeature];
};
export const fieldsReducer = propertyId => (
  acc,
  { geometry, cropzones = [], ...field }
) => {
  const fieldFeature =
    geometry && Object.keys(geometry).length !== 0
      ? feature(geometry, {
          ...field,
          propertyId,
          cropzones,
          $landType: 'field',
          $includedChildGeometries: childrenIdsWithIncludedGeometry(
            geometry,
            cropzones
          )
        })
      : {
          type: 'Feature',
          properties: {
            ...field,
            propertyId,
            cropzones,
            $landType: 'field',
            $includedChildGeometries: []
          }
        };

  const cropzoneFeatures = cropzones.reduce(
    cropZoneReducer(propertyId, field.id, geometry),
    []
  );

  return [...acc, fieldFeature, ...cropzoneFeatures];
};
// handle the success of loading properties data
export const reducerUpdatePropertiesDataState = (state, action) => {
  const mappedProperties = action.payload.properties.filter(
    property => property.status === 200 || property.status === null
  );
  const geoJsonFeatures = mappedProperties.flatMap(property =>
    property?.fields?.reduce(fieldsReducer(property.id), [])
  );
  return {
    ...state,
    data: {
      properties: mappedProperties
    },
    fieldsAndAreasGeoJSONCollection: formatGeoJSON(
      featureCollection(geoJsonFeatures)
    ),
    loading: false
  };
};
// update a single farm's data
export const reducerUpdateSingleFarmDataState = (state, action) => {
  const mappedProperties = action.payload.properties.filter(
    property => property.status === 200
  );
  const geoJsonFeatures = mappedProperties.flatMap(property =>
    property?.fields?.reduce(fieldsReducer(property.id), [])
  );
  const current = state.data.properties.find(
    property => property.id === mappedProperties[0]?.id
  );
  if (!current || !mappedProperties[0]) {
    return { ...state };
  }
  Object.assign(current, mappedProperties[0]);

  return {
    ...state,
    data: { properties: sortTree([...state.data.properties]) },
    fieldsAndAreasGeoJSONCollection: formatGeoJSON({
      type: 'FeatureCollection',
      features: [
        ...state.fieldsAndAreasGeoJSONCollection.features,
        ...geoJsonFeatures
      ]
    })
  };
};
// update field loading state
export const reducerUpdateFieldsLoadingState = value => state => ({
  ...state,
  fieldsLoading: value,
  isLoadingPaginatedProperties: value,
  loading: false
});

export const mergeFeatures = (existingFeatures, newFeatures) => {
  const featureMap = new Map();
  existingFeatures.forEach(_feature => {
    featureMap.set(_feature.properties.id, _feature);
  });
  newFeatures.forEach(_feature => {
    featureMap.set(_feature.properties.id, _feature);
  });
  return Array.from(featureMap.values());
};
// update GeoJSON collection features
export const reducerSetGeoJSONCollectionFeatures = (state, action) => ({
  ...state,
  fieldsAndAreasGeoJSONCollection: formatGeoJSON({
    ...state.fieldsAndAreasGeoJSONCollection,
    features: action.payload?.isEdition
      ? featureCollection(action.payload.value).features
      : mergeFeatures(
          state.fieldsAndAreasGeoJSONCollection.features,
          featureCollection(action.payload).features
        )
  })
});
// calculate and update centroid
export const reducerCalculateGeoJSONCentroidState = state => {
  if (!state.fieldsAndAreasGeoJSONCollection?.features?.length) {
    return {
      ...state,
      fieldsAndAreasCentroidCoordinates: undefined,
      loading: false
    };
  }
  const fieldsAndAreasCentroid = centroid(
    state.fieldsAndAreasGeoJSONCollection
  );
  return {
    ...state,
    loading: false,
    fieldsAndAreasCentroidCoordinates:
      fieldsAndAreasCentroid.geometry?.coordinates || undefined
  };
};
// reset the zoom and map position
export const reducerResetMapViewState = state => ({
  ...state,
  zoom: NATIONAL_ZOOM,
  fieldsAndAreasCentroidCoordinates: USA_CENTER_COORDINATES,
  loading: state.shapesLoading ? state.loading : false
});
// update paginated property data
export const reducerUpdatePaginatedPropertiesDataState = (state, action) => {
  if (action.payload.textSearch) {
    const mappedProperties = (action.payload?.properties || []).filter(
      property => property.status === 200 || property.status === null
    );
    const geoJsonFeatures = mappedProperties.flatMap(property =>
      property?.fields?.reduce(fieldsReducer(property.id), [])
    );
    const beforeSearch = state.beforeSearch
      ? state.beforeSearch
      : cloneDeep(state);

    return {
      ...state,
      beforeSearch,
      data: {
        orgId: action.payload.orgId,
        properties: mappedProperties,
        pagination: {
          referencePage: action.payload.referencePage,
          totalPages: action.payload?.totalPages,
          preLoadedPages: [action.payload.parentPage]
        }
      },
      fieldsAndAreasGeoJSONCollection: formatGeoJSON(
        featureCollection(geoJsonFeatures)
      ),
      loading: false
    };
  }

  const draftState = state.beforeSearch
    ? cloneDeep(state.beforeSearch)
    : cloneDeep(state);

  const { parentPage, referencePage } = action.payload;
  let startPage = parentPage - 10;
  startPage = startPage <= 0 ? 0 : startPage;
  const endPage = parentPage + 2;

  const mappedProperties = action.payload.properties
    .filter(property => property.status === 200 || property.status === null)
    .map(property => ({
      ...property,
      parentPage
    }));

  const propertiesMap = new Map();

  draftState.data.properties.forEach(property => {
    if (property.parentPage >= startPage && property.parentPage <= endPage) {
      propertiesMap.set(property.id, property);
    }
  });
  mappedProperties.forEach(property => {
    propertiesMap.set(property.id, property);
  });

  const filteredProperties = Array.from(propertiesMap.values());
  const geoJsonFeatures = filteredProperties.flatMap(property =>
    property?.fields?.reduce(fieldsReducer(property.id), [])
  );

  const existingFeatures = draftState.fieldsAndAreasGeoJSONCollection.features.filter(
    _feature =>
      filteredProperties.some(
        property => property.id === _feature.properties.id
      )
  );
  const mergedFeatures = mergeFeatures(
    existingFeatures,
    featureCollection(geoJsonFeatures).features
  );

  const preLoadedPages = Array.from(
    new Set(
      (filteredProperties || [])
        .map(prop => prop.parentPage)
        .concat(referencePage)
    )
  )
    .filter(page => page !== undefined)
    .sort((a, b) => a - b);

  return {
    ...draftState,
    data: {
      befofeSearch: null,
      orgId: action.payload.orgId,
      pagination: {
        totalPages:
          action.payload?.totalPages || draftState.data.pagination.totalPages,
        preLoadedPages,
        referencePage
      },
      properties: filteredProperties
    },
    fieldsAndAreasGeoJSONCollection: formatGeoJSON({
      type: 'FeatureCollection',
      features: mergedFeatures
    }),
    loading: false
  };
};
// update property costs
export const setPropertyCosts = (state, action) => {
  const newProperties = state.data.properties.map(farm => ({
    ...farm,
    fields:
      action.payload.farmId === farm.id
        ? farm.fields.map(field => ({
            ...field,
            cropzones: field.cropzones.map(cropzone => ({
              ...cropzone,
              costs: action.payload.costs[cropzone.id] ?? cropzone.costs
            }))
          }))
        : farm.fields
  }));
  return { ...state, data: { properties: [...newProperties] } };
};
// functions as a setState for the specified stateField
export const reducerSetState = stateField => (state, action) => ({
  ...state,
  [stateField]: setState(state[stateField], action.payload)
});
