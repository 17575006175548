import * as d3 from 'd3';
import { booleanClockwise } from '@turf/turf';

import { calculateCentroid } from './mapApiHelpers';

/*
  When using these functions, a "ring" is an array of coordinates that defines a polygon by
  connecting the coordinates consecutively.
  "Winding" is the direction of the connections between those coordinates in the ring, clockwise or counter-clockwise.
  Polygons that have cut-out sections in them have inner rings that define the shape of the cut-out polygon.
*/

export const outerRingIsClockwise = shape => {
  if (!shape.coordinates) return false;
  let outerRing;
  if (shape.type === 'MultiPolygon') {
    [[outerRing]] = shape.coordinates;
  } else [outerRing] = shape.coordinates;

  return booleanClockwise(outerRing);
};

export const isCounterClockwiseOuterRing = ring => !booleanClockwise(ring);

// outer rings have to be wound opposite of inner rings
export const svgWindCoordinates = coordinates =>
  coordinates.map(ring =>
    isCounterClockwiseOuterRing(ring) ? [...ring].reverse() : ring
  );

export const svgWindPolygon = polygon => ({
  ...polygon,
  coordinates: svgWindCoordinates(polygon.coordinates)
});

export const svgWindFeature = geometry => {
  if (!geometry.coordinates) return geometry;
  // will always need to go through winding process if there is more than 1 ring
  return outerRingIsClockwise(geometry) && geometry.coordinates.length === 0
    ? geometry
    : svgWindPolygon(geometry);
};

export const renderFeaturesToSvg = (
  features,
  svgSelector,
  { border, color, extent }
) => {
  const filteredFeatures = features.filter(
    feature => feature.geometry?.coordinates
  );

  if (filteredFeatures.length === 0) {
    return;
  }

  const featureCollection = {
    type: 'FeatureCollection',
    features: filteredFeatures.map(feature => ({
      ...feature,
      geometry: svgWindFeature(feature.geometry)
    }))
  };
  const cent = calculateCentroid(featureCollection);
  if (!cent?.geometry?.coordinates) {
    return;
  }
  const projection = d3
    .geoMercator()
    .center(cent.geometry.coordinates)
    .fitExtent(extent, featureCollection);
  const geoGen = d3.geoPath().projection(projection);
  const svgGroup = d3.select(svgSelector).select('g');
  const updater = svgGroup.selectAll('path').data(featureCollection.features);
  updater
    .enter()
    .append('path')
    .merge(updater)
    .attr('d', geoGen)
    .attr('stroke', border ?? color)
    .attr('fill', color);
  updater.exit().remove();
};
