/* eslint-disable func-names */
import React, { useContext, useEffect, useState } from 'react';
import { Context } from 'components/Store';
import {
  SET_REMOVE_PRODUCT_FAILURE,
  SET_TOAST_MESSAGE,
  SET_TOAST_TYPE,
  SET_SHOW_TOAST_REMOVE_PRODUCT
} from 'reducers/reducer';

import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import { v4 as uuidv4 } from 'uuid';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import EmptyTableState from 'components/EmptyTableState';
import NoSearchResultsImage from 'assets/no_search_results_found.svg';
import NoProductsImage from 'assets/no_products_found.svg';
import Toast from 'components/Toast';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import useProducts from 'hooks/useProducts';
import useInventoryProduct from 'screens/ProductList/hooks/useInventoryProduct';
import SortByProductSource from './components/ProductListSourceFilter';
import SortByProductType from './components/ProductListTypeFilter';
import EditProductDrawer from './components/EditProductDrawer';
import { PRODUCT_FILTER_OPTIONS } from './helpers/tableConstants';
import ProductListTable, {
  AddProductButton
} from './components/ProductListTable';
import AddProductDrawer from './components/AddProductDrawer';
import SimpleProductSearch from './components/SimpleProductSearch';
import './index.css';
import { ProductListContext } from './context/ProductListProvider';
import {
  buildCustomProductPayload,
  buildMasterlistProductPayload,
  parseFormulation
} from './helpers/formValuesToPayload';

const Products = () => {
  const {
    setIsLoadingProductInUse,
    clearAddProduct,
    tableSearchText
  } = useContext(ProductListContext);
  const amplitude = useContext(AmplitudeContext);
  const {
    products,
    getProducts,
    productsCount,
    isLoading,
    pageNo,
    goToPage,
    sort,
    getAllUseProducts,
    isLoadingAllProducts,
    filterByNameQuery,
    filterByProductTypes,
    filterBySources,
    sortBy,
    sortDir
  } = useProducts({ sortBy: 'productName', sortDir: 'asc' });

  const {
    addUpdateListPrice,
    addProducts,
    updateCustomProduct,
    updateError
  } = useInventoryProduct();

  const [toastMessage, setToastMessage] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState(null);
  const [productToEdit, setProductToEdit] = useState(null);
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [typeFilters, setTypeFilters] = useState([]);
  const [sourceFilters, setSourceFilters] = useState([]);
  const isSearchFilterActive = !!typeFilters.length || !!sourceFilters.length;
  const [showAddProductDrawer, setShowAddProductDrawer] = useState(false);
  const [editProductHeader, setEditProductHeader] = useState('');
  const [editProductSaveText, setEditProductSaveText] = useState('Save');
  const [selectedProductsList, setSelectedProductsList] = useState([]);
  const [, dispatch] = useContext(Context);

  const refreshProducts = () => {
    getProducts();
  };

  const handleAddProduct = () => {
    clearAddProduct();
    getAllUseProducts();
    setShowAddProductDrawer(true);
  };

  const handleTypeFilterChange = (_, __, e) => {
    const selected = e.map(({ key }) => key);
    filterByProductTypes(selected);
    setTypeFilters(selected);
  };

  const handleSourceFilterChange = (_, __, e) => {
    const selected = e.map(({ key }) => key);
    filterBySources(selected);
    setSourceFilters(selected);
  };
  const handleOnAddCustomProduct = () => {
    const product = {
      productId: uuidv4(),
      isNew: true,
      custom: true,
      formulation: {}
    };
    setProductToEdit(product);
    setEditProductHeader('Add Custom Product');
    setEditProductSaveText('Create Product');
    setShowEditDrawer(true);
  };

  const handleOnEdit = product => {
    setEditProductSaveText('Save');
    setProductToEdit(product);
    if (product.custom ?? product.isNew) {
      setEditProductHeader('Edit Custom Product');
    } else if (!showAddProductDrawer && !product.custom) {
      setEditProductHeader('Edit Product');
    } else {
      setEditProductHeader('Add to Product List');
    }
    if (
      showAddProductDrawer &&
      selectedProductsList.find(prod => prod.id === product.id) &&
      !product.isNew
    ) {
      setEditProductHeader('Edit Product');
    }
    setShowEditDrawer(true);
  };

  const handleEdit = async values => {
    if (showAddProductDrawer) {
      // Add Products logic
      const isInTheList = selectedProductsList.find(el => {
        const currentId = el.id ?? el.productId;
        const newProductId = values.id ?? values.productId;
        return currentId === newProductId;
      });
      if (!isInTheList) {
        // Select a new product
        setSelectedProductsList([...selectedProductsList, values]);
      } else if (!productToEdit.isNew && !values.custom) {
        // Soft edit selected masterlist product
        const oldItemIndex = selectedProductsList.findIndex(pr => {
          return pr.productId === productToEdit.productId;
        });
        const newItem = { ...selectedProductsList[oldItemIndex] };
        newItem.averagePricePerUnit = values.averagePricePerUnit;
        newItem.averagePricePerUnitUnit = values.averagePricePerUnitUnit;
        newItem.packageWeight = values?.packageWeight;
        const newSelectedProducts = [...selectedProductsList];
        newSelectedProducts.splice(oldItemIndex, 1, newItem);
        setSelectedProductsList(newSelectedProducts);
      } else {
        // Soft edit selected custom product
        const newItem = buildCustomProductPayload(values);
        newItem.isNew = true;
        newItem.producttype = values.type;
        newItem.productId = productToEdit.productId;
        const oldItemIndex = selectedProductsList.findIndex(pr => {
          return pr.productId === productToEdit.productId;
        });
        const newSelectedProducts = [...selectedProductsList];
        newSelectedProducts.splice(oldItemIndex, 1, newItem);
        setSelectedProductsList(newSelectedProducts);
        amplitude.sendEventToAmplitude(
          amplitude.events.epic.ProductList.editCustomProductCart,
          {
            productId: newItem.productId
          }
        );
      }
    } else {
      // Hard edit logic
      if (values.custom) {
        // Hard add/edit custom product
        const payload = buildCustomProductPayload(values);
        await updateCustomProduct(payload);
      } else {
        // Hard add/edit masterlist product
        const payload = buildMasterlistProductPayload(values);
        await addUpdateListPrice(payload);
      }
      // Toast logic
      if (updateError) {
        setToastType('error');
        setToastMessage('Unable to save changes');
      } else {
        setToastType('success');
        setToastMessage('Changes saved.');
      }
      setShowToast(true);
      refreshProducts();
    }

    setShowEditDrawer(false);
    setProductToEdit();
  };

  const handleAddSubmit = () => {
    if (selectedProductsList && showAddProductDrawer) {
      const itemsToUpdate = [];
      // eslint-disable-next-line react/prop-types

      selectedProductsList
        .filter(x => !x.isNew)
        .forEach(pr => {
          itemsToUpdate.push({
            productId: pr.id,
            averagePricePerUnit: pr.averagePricePerUnit ?? 0,
            averagePricePerUnitUnit: pr.averagePricePerUnitUnit,
            ...(pr?.packageWeight
              ? { packageWeight: parseFloat(pr?.packageWeight) }
              : {})
          });
        });
      const itemsToCreate = selectedProductsList
        .filter(x => x.isNew)
        .map(product => ({
          name: product.name,
          manufacturer: product.manufacturer,
          standardUnit: product.standardUnit,
          type: product.type,
          makeUp: product.makeUp,
          density: product.density,
          stdFactor: product.stdFactor,
          stdPackageUnit: product.stdPackageUnit,
          formulation: parseFormulation(product.formulation),
          averagePricePerUnit: product.averagePricePerUnit,
          averagePricePerUnitUnit: product.averagePricePerUnitUnit,
          organizationId: product.organizationId
        }));
      addProducts({
        itemsToUpdate,
        itemsToCreate
      })
        .then(() => {
          setShowToast(true);
          setToastMessage('Products added to list.');
          setToastType('success');
          setShowAddProductDrawer(false);
          clearAddProduct();
          setSelectedProductsList([]);
          refreshProducts();
        })
        .catch(() => {
          setShowToast(true);
          setToastType('error');
          setToastMessage('Product not added.');
        });
    }
  };

  useEffect(() => {
    setIsLoadingProductInUse(isLoadingAllProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAllProducts]);

  const onCloseHandler = () => {
    dispatch({
      type: SET_TOAST_MESSAGE,
      payload: 'null'
    });
    dispatch({
      type: SET_TOAST_TYPE,
      payload: 'null'
    });
    dispatch({
      type: SET_SHOW_TOAST_REMOVE_PRODUCT,
      payload: false
    });
    dispatch({
      type: SET_REMOVE_PRODUCT_FAILURE,
      payload: false
    });
    setShowToast(false);
  };
  const toastHandler = () => {
    let messageDivText = null;
    if (toastMessage === 'Unable to remove product.') {
      messageDivText =
        'An error prevented your product(s) from being removed from your list. Please try again.';
    } else if (toastMessage === 'Product not added.') {
      messageDivText =
        'An error prevented your product(s) from being added to your list. Please try again.';
    }

    if (messageDivText) {
      return (
        <Toast
          type={toastType}
          isBold
          open={showToast}
          title={toastMessage}
          onClose={onCloseHandler}
          timeout={-1}
        >
          <div>{messageDivText}</div>
          <div className="flex flex-col">
            <Button
              style={{ alignSelf: 'end', marginTop: '8px' }}
              type={ButtonType.primary}
              onClick={() => {
                location.href = 'https://www.syngentadigital.com/us-en';
              }}
            >
              Contact Support
            </Button>
          </div>
        </Toast>
      );
    }

    return (
      <Toast
        type={toastType}
        isBold
        open={showToast}
        title={toastMessage}
        timeout={5000}
        onClose={onCloseHandler}
      />
    );
  };

  return (
    <div className="h-full w-full p-4 flex flex-col" data-testid="products">
      <Breadcrumb hideCropSeasonDropdown>
        <Breadcrumb.Item title="Products" value="All Products" isLast />
      </Breadcrumb>
      <div className="flex items-center justify-between mb-4 h-8">
        <div className="flex py-2px h-full">
          <span className="text-xl font-semibold mr-5">Product List</span>
          <div className="mr-4">
            <SimpleProductSearch setSearchText={filterByNameQuery} />
          </div>
          <SortByProductType
            typeFilters={typeFilters}
            handleFilterChange={handleTypeFilterChange}
            typeFilterOptions={PRODUCT_FILTER_OPTIONS}
          />
          <SortByProductSource
            sourceFilters={sourceFilters}
            handleFilterChange={handleSourceFilterChange}
          />
        </div>
        <AddProductButton onClick={handleAddProduct} />
        <AddProductDrawer
          visible={showAddProductDrawer}
          onClose={() => {
            clearAddProduct();
            setShowAddProductDrawer(false);
            setSelectedProductsList([]);
          }}
          setShowEditDrawer={setShowEditDrawer}
          selectedProductsList={selectedProductsList}
          setSelectedProductsList={setSelectedProductsList}
          handleOnEdit={handleOnEdit}
          handleAddProductsSubmit={handleAddSubmit}
          handleOnAddCustomProduct={handleOnAddCustomProduct}
        />
      </div>
      <EditProductDrawer
        handleEdit={handleEdit}
        product={productToEdit}
        visible={showEditDrawer}
        onClose={() => setShowEditDrawer(false)}
        editProductHeader={editProductHeader}
        saveText={editProductSaveText}
      />
      <div
        className="flex flex-auto justify-center"
        style={{ maxHeight: 'calc(100% - 3rem)' }}
      >
        <div
          className={`bg-white flex flex-col justify-center items-center ${
            products.length || isLoading ? 'flex-auto' : 'h-fit'
          } w-full rounded-lg shadow-sm-card overflow-hidden border-1 border-neutral-30`}
        >
          <ProductListTable
            products={products}
            productsCount={productsCount}
            isLoading={isLoading}
            loadingMessage={
              tableSearchText.length ? 'Searching Products...' : null
            }
            pageNo={pageNo}
            setPageNo={goToPage}
            onSort={sort}
            sortBy={sortBy}
            sortDir={sortDir}
            productToEdit={productToEdit}
            setProductToEdit={setProductToEdit}
            showEditDrawer={showEditDrawer}
            setShowEditDrawer={setShowEditDrawer}
            handleOnEdit={handleOnEdit}
            editProductHeader={editProductHeader}
            editProductSaveText={editProductSaveText}
            handleEdit={handleEdit}
            setToastType={setToastType}
            setToastMessage={setToastMessage}
            setShowToast={setShowToast}
            refreshProducts={refreshProducts}
            emptyState={
              isSearchFilterActive || tableSearchText.length ? (
                <EmptyTableState
                  overlayIcon={NoSearchResultsImage}
                  message="We were unable to find any products using the search & filter criteria. Try a different search and/or filter."
                />
              ) : (
                <EmptyTableState
                  overlayIcon={NoProductsImage}
                  title="No Products Found"
                  message="To view your products, either create a production record or
          manually add a product."
                  button={<AddProductButton onClick={handleAddProduct} />}
                />
              )
            }
          />

          {toastHandler()}
        </div>
      </div>
    </div>
  );
};
export default Products;
