import { uniqBy } from 'lodash';
import { deleteCache } from 'utilities/helpers/caching';

export const GLOBAL_SET_TOOLBAR = 'SET_TOOLBAR';
export const GLOBAL_SET_ERROR = 'SET_ERROR';
export const GLOBAL_SET_ORGANIZATION = 'SET_ORGANIZATION';
export const GLOBAL_SET_PERMISSION = 'SET_PERMISSION';
export const GLOBAL_LOGOUT_USER = 'LOGOUT_USER';
export const GLOBAL_BACKGROUND_COLOR = 'BACKGROUND_COLOR';
export const GLOBAL_SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';
export const GLOBAL_SET_ORGANIZATION_PERMISSIONS =
  'SET_ORGANIZATION_PERMISSIONS';
export const GLOBAL_SET_USER_ENVIRONMENT = 'SET_USER_ENVIRONMENT';
export const GLOBAL_SET_SELECTED_CONTEXT = 'SET_SELECTED_CONTEXT';
export const GLOBAL_SET_CONVERT_PLAN = 'SET_CONVERT_PLAN';
export const SWITCH_WEATHER_TOGGLE = 'SWITCH_WEATHER_TOGGLE';
export const RESET_PROPERTY_NAME = 'RESET_PROPERTY_NAME';
export const SELECT_MEMBER = 'SELECT_MEMBER';
export const ORG_LOADING = 'ORG_LOADING';
export const SELECT_PROPERTY = 'SELECT_PROPERTY';
export const EXPAND_PROPERTIES = 'EXPAND_PROPERTIES';
export const COLLAPSE_PROPERTY = 'COLLAPSE_PROPERTY';
export const CROP_SEASONS = 'SET_SEASONS';

export const DELETE_SEASON = 'DELETE_SEASON';
export const DELETE_YIELD = 'DELETE_YIELD';
export const SELECTED_FIELD_ROW_ID = 'SELECTED_FIELD_ROW_ID';
export const IS_ENABLE_INFO_TAB_EDIT = 'IS_ENABLE_INFO_TAB_EDIT';
// Mass Assigner
export const IS_MASS_ASSIGNER_ACTIVE = 'IS_MASS_ASSIGNER_ACTIVE';
export const FIELDS_TO_MASSIVE_ASSIGN = 'FIELDS_TO_MASSIVE_ASSIGN';
export const MASSIVE_CHANGE_SUMMARY = 'MASSIVE_CHANGE_SUMMARY';
export const RESET_MASS_ASSIGNER = 'RESET_MASS_ASSIGNER';
export const IS_FARM_EDITED = 'IS_FARM_EDITED';
export const UPDATE_CROP_NAME = 'UPDATE_CROP_NAME';
export const MASS_ASSIGNER_SUCCESS_COUNTER = 'MASS_ASSIGNER_SUCCESS_COUNTER';
export const MASS_ASSIGNER_REJECTIONS = 'MASS_ASSIGNER_REJECTIONS';
// Export PDF Maps
export const IS_EXPORT_PDF_MAPS_ACTIVE = 'IS_EXPORT_PDF_MAPS_ACTIVE';
export const DISMISS_EXPORT_PDF_MAPS_ALERT = 'DISMISS_EXPORT_PDF_MAPS_ALERT';
export const EXPORT_PDF_MAPS_STEP = 'EXPORT_PDF_MAPS_STEP';
export const SET_MAP_PRINTABLE_AREA_BBOX = 'SET_MAP_PRINTABLE_AREA_BBOX';
export const SET_PROPERTY_MAP_FIELDS_CONFIG = 'SET_PROPERTY_MAP_FIELDS_CONFIG';
export const SET_CROP_COLORS = 'SET_CROP_COLORS';
export const SET_MAP_LABEL_CONFIG = 'SET_MAP_LABEL_CONFIG';
export const SET_MAP_LEGEND_LABELS = 'SET_MAP_LEGEND_LABELS';
export const SET_PROPERTY_MAP_FOOTER_CONFIG = 'SET_PROPERTY_MAP_FOOTER_CONFIG';
export const SET_MAP_STYLE_IMAGE = 'SET_MAP_STYLE_IMAGE';
export const SET_IS_SORT_BY_CROPS = 'SET_SORT_BY_CROPS';

export const IS_GLOBAL_CROPSEASON_LOADING = 'IS_GLOBAL_CROPSEASON_LOADING';
export const SEARCH_TEXT_VALUE_FFT = 'SEARCH_TEXT_VALUE_FFT';
export const IS_FIELD_IMPORT_SUCCESSFUL = 'IS_FIELD_IMPORT_SUCCESSFUL';
export const IMPORTED_FIELDS = 'IMPORTED_FIELDS';
export const UPLOADED_FILES = 'UPLOADED_FILES';
export const SET_LOAD_PROPERTIES = 'SET_LOAD_PROPERTIES';
export const IS_MAP_MOVING = 'IS_MAP_MOVING';
export const IS_FULL_SCREEN_NEEDED = 'IS_FULL_SCREEN_NEEDED';
export const SET_LOAD_COSTS = 'SET_LOAD_COSTS';
export const SELECTED_YIELD_COMMODITY = 'SELECTED_YIELD_COMMODITY';
export const SET_DISABLE_INITIAL_ZOOM = 'SET_DISABLE_INITIAL_ZOOM';
export const SET_CROP_ZONE_MATCHER_TYPE = 'SET_CROP_ZONE_MATCHER_TYPE';
export const SET_SHOW_TOAST_CROP_ZONE_MATCHER =
  'SET_SHOW_TOAST_CROP_ZONE_MATCHER';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const SET_SHOW_TOAST_REMOVE_PRODUCT = 'SET_SHOW_TOAST_REMOVE_PRODUCT';
export const SET_TOAST_TYPE = 'SET_TOAST_TYPE';
export const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE';
export const SET_REMOVE_PRODUCT_FAILURE = 'SET_REMOVE_PRODUCT_FAILURE';

export const SET_CROP_ZONE_MATCHER_INFO = 'SET_CROP_ZONE_MATCHER_INFO';
export const SET_PROPERTY_FILTERS = 'SET_PROPERTY_FILTERS';
export const SET_SIDEBAR_STATUS = 'SET_SIDEBAR_STATUS';
export const SET_SHOW_TOAST_SALE_LOCATION = 'SET_SHOW_TOAST_SALE_LOCATION';
export const SET_SORT_RULES = 'SET_SORT_RULES';
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';
export const SET_SHOW_TOAST_CHANGES_SAVED = 'SET_SHOW_TOAST_CHANGES_SAVED';
export const SET_TASK_CREATION_FROM = 'SET_TASK_CREATION_FROM';
export const SET_FILTERED_GEOJSON = 'SET_FILTERED_GEOJSON';

const event = new Event('cwf-expired');
const reducer = (state, action) => {
  switch (action.type) {
    case GLOBAL_SET_TOOLBAR:
      return {
        ...state,
        toolbarComponents: action.payload
      };
    case GLOBAL_SET_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case GLOBAL_SET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload
      };
    case GLOBAL_SET_ORGANIZATION:
      if (action.payload.id !== state.organization.id) {
        deleteCache();
      }
      return {
        ...state,
        organization: action.payload
      };
    case GLOBAL_SET_PERMISSION:
      return {
        ...state,
        permission: action.payload
      };
    case GLOBAL_LOGOUT_USER:
      window.dispatchEvent(event);
      return {
        ...state,
        permission: null
      };
    case GLOBAL_BACKGROUND_COLOR:
      return {
        ...state,
        backgroundColor: action.payload
      };
    case GLOBAL_SET_ORGANIZATION_PERMISSIONS:
      return {
        ...state,
        organizationPermissions: action.payload
      };
    case GLOBAL_SET_USER_ENVIRONMENT:
      return {
        ...state,
        organization: action.organization,
        permission: action.permission,
        organizationPermissions: action.organizationPermissions
      };
    // used to communicate selected context from the Breadcrumb to the active components
    case GLOBAL_SET_SELECTED_CONTEXT:
      return {
        ...state,
        selectedContext: action.payload
      };
    case GLOBAL_SET_CONVERT_PLAN:
      return {
        ...state,
        planToConvert: action.payload
      };
    case SWITCH_WEATHER_TOGGLE:
      return {
        ...state,
        weatherAutomaticToggle: !state.weatherAutomaticToggle
      };
    case RESET_PROPERTY_NAME:
      return {
        ...state,
        shouldResetPropertyName: !state.shouldResetPropertyName
      };
    case SELECT_MEMBER:
      return {
        ...state,
        loggedInUserOrgPermission: action.payload
      };
    case ORG_LOADING:
      return {
        ...state,
        loadingOrg: action.payload
      };
    case SELECT_PROPERTY:
      return {
        ...state,
        selectedProperty: action.payload
      };
    case EXPAND_PROPERTIES:
      return {
        ...state,
        expandedPropertyIds: [...state.expandedPropertyIds, action.payload]
      };
    case COLLAPSE_PROPERTY:
      return {
        ...state,
        expandedPropertyIds: state.expandedPropertyIds.filter(
          id => id !== action.payload
        )
      };
    case SET_FILTERED_GEOJSON:
      return {
        ...state,
        allFFTGeoJson: action.payload
      };
    case CROP_SEASONS:
      return {
        ...state,
        cropSeasons: action.payload
      };
    case DELETE_SEASON:
      return {
        ...state,
        cropSeasons: state.cropSeasons.filter(
          season => season.id !== action.payload
        )
      };
    case DELETE_YIELD:
      return {
        ...state,
        yieldEntryId: action.payload
      };
    case SELECTED_FIELD_ROW_ID:
      return {
        ...state,
        selectedFieldRowId: action.payload
      };
    case IS_ENABLE_INFO_TAB_EDIT:
      return {
        ...state,
        isEnableInfoTabEdit: action.payload
      };
    case IS_MASS_ASSIGNER_ACTIVE:
      return {
        ...state,
        isMassAssignerActive: action.payload
      };
    // Export PDF Maps
    case IS_EXPORT_PDF_MAPS_ACTIVE:
      return {
        ...state,
        isExportPDFMapsActive: action.payload
      };
    case DISMISS_EXPORT_PDF_MAPS_ALERT:
      return {
        ...state,
        isExportPDFMapsAlertDismissed: true
      };
    case EXPORT_PDF_MAPS_STEP:
      return {
        ...state,
        exportPDFMapsStep: action.payload
      };
    case SET_MAP_PRINTABLE_AREA_BBOX:
      return {
        ...state,
        mapPrintableAreaBbox: action.payload
      };
    case SET_PROPERTY_MAP_FIELDS_CONFIG:
      return {
        ...state,
        propertyMapFieldsConfig: action.payload
      };
    case SET_CROP_COLORS:
      return {
        ...state,
        cropColors: {
          ...state.cropColors,
          ...action.payload
        }
      };
    case SET_MAP_LABEL_CONFIG:
      return {
        ...state,
        mapLabelConfig: {
          ...state.mapLabelConfig,
          ...action.payload
        }
      };
    case SET_MAP_LEGEND_LABELS:
      return {
        ...state,
        mapLegendLabels: {
          ...state.mapLegendLabels,
          ...action.payload
        }
      };
    case SET_MAP_STYLE_IMAGE:
      return {
        ...state,
        mapStyleImage: action.payload
      };
    case SET_PROPERTY_MAP_FOOTER_CONFIG:
      return {
        ...state,
        propertyMapFooterConfig: {
          ...state.propertyMapFooterConfig,
          ...action.payload
        }
      };
    case FIELDS_TO_MASSIVE_ASSIGN:
      return {
        ...state,
        fieldsToMassiveAssign: uniqBy(action.payload, 'id')
      };
    case MASSIVE_CHANGE_SUMMARY:
      return {
        ...state,
        massiveChangeSummary: action.payload
      };
    case RESET_MASS_ASSIGNER:
      return {
        ...state,
        isMassAssignerActive: action.payload || false,
        isExportPDFMapsActive: action.payload || false,
        massiveChangeSummary: {},
        fieldsToMassiveAssign: []
      };
    case IS_FARM_EDITED:
      return {
        ...state,
        isFarmEdited: action.payload
      };
    case UPDATE_CROP_NAME:
      return {
        ...state,
        updateCropName: action.payload
      };
    case MASS_ASSIGNER_SUCCESS_COUNTER:
      return {
        ...state,
        massAssignerSuccessCounter: action.payload
      };
    case MASS_ASSIGNER_REJECTIONS:
      return {
        ...state,
        massAssignerRejections: action.payload
      };
    case IS_GLOBAL_CROPSEASON_LOADING:
      return {
        ...state,
        isGlobalCropSeasonLoading: action.payload
      };
    case SEARCH_TEXT_VALUE_FFT:
      return {
        ...state,
        searchTextValueFFT: action.payload
      };
    case IS_FIELD_IMPORT_SUCCESSFUL:
      return {
        ...state,
        isFieldImportedSuccessful: action.payload
      };
    case IMPORTED_FIELDS:
      return {
        ...state,
        importedFields: action.payload
      };
    case UPLOADED_FILES:
      return {
        ...state,
        uploadedFiles: action.payload
      };
    case SET_LOAD_PROPERTIES:
      return {
        ...state,
        loadingProperties: action.payload,
        selectedProperty: {},
        loadTimestamp: new Date().valueOf()
      };
    case IS_MAP_MOVING:
      return {
        ...state,
        isMapMoving: action.payload
      };
    case IS_FULL_SCREEN_NEEDED:
      return {
        ...state,
        isFullScreenNeeded: action.payload
      };
    case SET_LOAD_COSTS:
      return {
        ...state,
        loadingCosts: action.payload
      };
    case SELECTED_YIELD_COMMODITY:
      return {
        ...state,
        selectedYieldCommodity: action.payload
      };
    case SET_DISABLE_INITIAL_ZOOM:
      return {
        ...state,
        zoomInitialMap: action.payload
      };
    case SET_CROP_ZONE_MATCHER_TYPE:
      return {
        ...state,
        cropZoneMatcherType: action.payload
      };
    case SET_SHOW_TOAST_REMOVE_PRODUCT:
      return {
        ...state,
        showToastRemoveProduct: action.payload
      };
    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload
      };
    case SET_TOAST_TYPE:
      return {
        ...state,
        toastType: action.payload
      };
    case SET_TOAST_MESSAGE:
      return {
        ...state,
        toastMessage: action.payload
      };
    case SET_REMOVE_PRODUCT_FAILURE:
      return {
        ...state,
        removeProductFailure: action.payload
      };
    case SET_SHOW_TOAST_CROP_ZONE_MATCHER:
      return { ...state, showToastCropZoneMatcher: action.payload };
    case SET_CROP_ZONE_MATCHER_INFO:
      return {
        ...state,
        additionalInformationToastCropZoneMatcher: action.payload
      };
    case SET_PROPERTY_FILTERS:
      return {
        ...state,
        propertyFilters: {
          ...state.propertyFilters,
          ...action.payload
        }
      };
    case SET_SIDEBAR_STATUS:
      return {
        ...state,
        isSidebarOpen: action.payload
      };
    case SET_SHOW_TOAST_SALE_LOCATION:
      return {
        ...state,
        showToastSaleLocation: action.payload
      };
    case SET_SORT_RULES:
      return {
        ...state,
        sortRules: action.payload
      };
    case SET_IS_SORT_BY_CROPS:
      return {
        ...state,
        isSortByCrop: action.payload
      };
    case SET_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload
      };
    case SET_SHOW_TOAST_CHANGES_SAVED:
      return {
        ...state,
        showToastChangesSaved: action.payload
      };
    case SET_TASK_CREATION_FROM:
      return {
        ...state,
        taskCreationFrom: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
