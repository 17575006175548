import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Input, SimpleModal } from '@agconnections/grow-ui';
import PriceUnitField from 'components/PriceUnitField';
import { getUnitLabel } from 'helpers/unitsAbbreviation';
import useMasterProducts from 'hooks/useMasterProducts';

const ProductsModal = ({
  open,
  onClose,
  product,
  applicationType,
  memberRole,
  memberFinancialAccess
}) => {
  const [loadedProduct, setLoadedProduct] = useState(product);
  const { getStdUnits } = useMasterProducts();
  const getApplicationTypeLabel = type => {
    let label = 'Rate/Tank';
    if (type === 'ratePerAreaValue') {
      label = 'Rate/Area';
    } else if (type === 'totalProductValue') {
      label = 'Total Product';
    }
    return label;
  };

  const checkForUndefined = value => value || 0;

  const setPermissions = () => {
    if (memberRole === 'View Only' && memberFinancialAccess === 'none') {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (product && !product.stdpackageunit) {
      getStdUnits(product).then(res => {
        setLoadedProduct(res);
      });
    } else {
      setLoadedProduct(product);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return (
    <SimpleModal open={open} close={onClose} onConfirm={onClose} size="sm">
      <div className="flex items-center justify-between">
        <div className="font-bold text-lg">{product.productName}</div>
      </div>
      <div className="flex items-center justify-between">
        <div className="text-sm text-neutral-300">
          {product.manufacturerName}
        </div>
      </div>
      <div className="mt-12">
        <div className="grid grid-cols-3 gap-6 mb-12">
          <div className="my-2">
            <Input
              label={getApplicationTypeLabel(applicationType)}
              value={product?.[applicationType]}
              disabled
            />
          </div>
          <div className="my-2 col-span-2">
            <PriceUnitField
              units={[
                {
                  key:
                    product.averagePriceUnitAtTimeOfCreation ||
                    loadedProduct.stdpackageunit,
                  value:
                    product.averagePriceAtTimeOfCreation ||
                    loadedProduct.stdpackageunit,
                  label: getUnitLabel(
                    product.averagePriceUnitAtTimeOfCreation ||
                      loadedProduct.stdpackageunit
                  )
                }
              ]}
              disabled
              defaultUnit={
                product.averagePriceUnitAtTimeOfCreation ||
                loadedProduct.stdpackageunit
              }
              fieldNameInput="averagePriceAtTimeOfCreation"
              fieldNameUnit="averagePriceUnitAtTimeOfCreation"
              object={product}
              minWidthDropdown="60%"
            />
          </div>
          <div className="my-2">
            <Input
              label="Applied Area"
              value={`${product.appliedAreaValue} ac`}
              disabled
            />
          </div>
          <div className="my-2">
            <Input
              label="Applied Area %"
              value={`${product.coveragePercent * 100}%`}
              disabled
            />
          </div>
          <div className="my-2">
            <Input
              label="Total Grower Cost"
              value={
                setPermissions()
                  ? `$${(
                      product.totalCostAtTimeOfCreation *
                      (checkForUndefined(
                        product.shareOwnerInformation
                          ?.percentGrowerResponsibility
                      ) /
                        100)
                    ).toFixed(2)}`
                  : null
              }
              disabled
            />
          </div>
          <div className="my-2">
            <Input
              label="Application Method"
              value={product.applicationMethod}
              disabled
            />
          </div>
          <div className="my-2">
            <Input
              label="Target Pest"
              value={product.targetPest?.name}
              disabled
            />
          </div>
          <div className="my-2">
            <Input
              label="Grower Share"
              value={`${checkForUndefined(
                product.shareOwnerInformation?.percentGrowerResponsibility
              )}%`}
              disabled
            />
          </div>
          <div className="my-2">
            <Input
              label="From Inventory"
              value={`${checkForUndefined(
                product.shareOwnerInformation?.totalFromGrowerInventoryQuantity
              )}%`}
              disabled
            />
          </div>
        </div>
      </div>
    </SimpleModal>
  );
};

ProductsModal.defaultProps = {
  open: false,
  onClose: () => {}
};
ProductsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  applicationType: PropTypes.string.isRequired,
  product: PropTypes.shape({
    applicationMethod: PropTypes.string,
    appliedAreaUnit: PropTypes.string,
    appliedAreaValue: PropTypes.number,
    areaUnit: PropTypes.string,
    associatedProduct: PropTypes.bool,
    averagePriceAtTimeOfCreation: PropTypes.number,
    averagePriceUnitAtTimeOfCreation: PropTypes.string,
    coveragePercent: PropTypes.number,
    custom: PropTypes.bool,
    hasCost: PropTypes.bool,
    id: PropTypes.string,
    manufacturerName: PropTypes.string,
    productId: PropTypes.string,
    productName: PropTypes.string,
    ratePerAreaUnit: PropTypes.string,
    ratePerAreaValue: PropTypes.number,
    ratePerTankUnit: PropTypes.string,
    ratePerTankValue: PropTypes.number,
    shareOwnerInformation: PropTypes.shape({
      percentGrowerResponsibility: PropTypes.number,
      totalFromGrowerInventoryQuantity: PropTypes.number,
      totalFromGrowerInventoryUnit: PropTypes.string
    }),
    specificCostPerUnit: PropTypes.number,
    specificCostUnit: PropTypes.string,
    targetPest: PropTypes.shape({
      latinNames: PropTypes.string,
      name: PropTypes.string
    }),
    totalCostAtTimeOfCreation: PropTypes.number,
    totalProductUnit: PropTypes.string,
    totalProductValue: PropTypes.number,
    stdpackageunit: PropTypes.string
  }).isRequired,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired
};

export default ProductsModal;
