/* eslint-disable import/prefer-default-export */
import { centerOfMass } from '@turf/turf';

export function formatGeoJSON(geoJSON) {
  return {
    ...geoJSON,
    features: geoJSON.features.flatMap(feature => {
      const { archived, $landType, name, cropzones } = feature.properties;

      // Check if the feature has geometry
      if (!feature.geometry) {
        let defaultLabel = '';
        if (archived) {
          defaultLabel = 'ARCHIVED';
        } else if ($landType === 'field') {
          defaultLabel = name || 'Untitled-Field';
        }

        let fieldAndCropZoneLabel = '';
        if (archived) {
          fieldAndCropZoneLabel = 'ARCHIVED';
        } else if (cropzones?.length) {
          fieldAndCropZoneLabel = cropzones
            .map(cropZone => cropZone.name)
            .join('\n');
        } else {
          fieldAndCropZoneLabel = name || 'Untitled-Field';
        }

        return {
          ...feature,
          properties: {
            ...feature.properties,
            defaultLabel,
            fieldAndCropZoneLabel
          }
        };
      }

      const center = centerOfMass(feature);
      const labelPosition = center.geometry.coordinates;

      let defaultLabel = '';
      if (archived) {
        defaultLabel = 'ARCHIVED';
      } else if ($landType === 'field') {
        defaultLabel = name || 'Untitled-Field';
      }

      let fieldAndCropZoneLabel = '';
      if (archived) {
        fieldAndCropZoneLabel = 'ARCHIVED';
      } else if (cropzones?.length) {
        fieldAndCropZoneLabel = cropzones
          .map(cropZone => cropZone.name)
          .join('\n');
      } else {
        fieldAndCropZoneLabel = name || 'Untitled-Field';
      }

      if (archived) {
        return {
          ...feature,
          properties: {
            ...feature.properties,
            defaultLabel,
            fieldAndCropZoneLabel
          }
        };
      }

      const labelFeature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: labelPosition
        },
        properties: {
          defaultLabel,
          fieldAndCropZoneLabel
        }
      };

      return [feature, labelFeature];
    })
  };
}
