import React, { useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Switch, Input } from 'syngenta-digital-cropwise-react-ui-kit';
import useDebounce from 'hooks/useDebounce';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import Editor from 'screens/Property/PropertiesLanding/components/Editor';
import CropSeasonSingleSelectionDropdown from 'components/CropSeasonSingleSelectionDropdown';
import SubmissionFooter from 'screens/Integrations/components/SubmissionFooter';
import SearchIcon from 'assets/search.svg';
import CwFPagination from 'components/CwFPagination';
import useFormikHandler from 'screens/Integrations/hooks/useFormikHandler';
import useIncomingInvoiceApi from 'screens/Integrations/hooks/useIncomingInvoiceApi';
import events from 'utilities/amplitude/events';
import { toastManager } from 'components/ToastContainer/toastManager';
import { AgVendContext } from '../context/Provider';
import AgVendInvoiceTable from './components/AgVendInvoicesTable';

const AgVendInvoiceList = () => {
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [pageSize] = useState(20);
  const [includePreviousImported, setIncludePreviousImported] = useState(false);
  const [filteredInvoices, setFilteredInvoices] = useState([]);

  const { triggerAnalyticsEvent } = useSubmissionAnalytics();
  const { goForward, releaseStepForward } = useContext(AgVendContext);

  const { loading: isLoading, listIncomingInvoices } = useIncomingInvoiceApi();

  const { values, setFieldValue } = useFormikContext();
  const {
    selectedCropSeasons,
    setSelectedCropSeasons,
    selectedInvoices,
    setSelectedInvoices
  } = useFormikHandler();

  const timeDelay = 300;
  const debouncedSearchTerm = useDebounce(searchText, timeDelay);

  useEffect(() => {
    async function listInvoices() {
      if (selectedCropSeasons?.length > 0) {
        triggerAnalyticsEvent(
          events.epic.Integrations.IncomingInvoice.invoiceList,
          {
            vendor: INTEGRATIONS.agVend
          }
        );
        const response = await listIncomingInvoices({
          vendor: INTEGRATIONS.agVend.toLowerCase(),
          displayImported: includePreviousImported,
          searchText: debouncedSearchTerm,
          page: currentPage - 1,
          limit: pageSize
        });
        if (response) {
          releaseStepForward();
          setFieldValue('invoices', response.data);
        }
      }
    }
    listInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCropSeasons,
    includePreviousImported,
    debouncedSearchTerm,
    currentPage
  ]);

  useEffect(() => {
    if (values.invoices) {
      const { data: responseData, elements } = values.invoices;

      setTotalElements(elements);
      setFilteredInvoices(responseData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.invoices]);

  const handleSelectInvoice = invoice => {
    if (!selectedInvoices?.length > 0) {
      return setSelectedInvoices([invoice]);
    }

    const notMatchInvoices = selectedInvoices?.filter(
      ({ id }) => id !== invoice.id
    );

    if (notMatchInvoices.length !== selectedInvoices?.length) {
      return setSelectedInvoices(notMatchInvoices);
    }

    return setSelectedInvoices([invoice].concat(selectedInvoices));
  };

  const handleCropSeasonSelect = seasonSelected => {
    setSelectedCropSeasons([seasonSelected]);
  };

  const onNextHandler = async () => {
    if (selectedInvoices?.length > 20) {
      toastManager.warn('You can only select up to 20 invoices');
      return;
    }
    triggerAnalyticsEvent(
      events.epic.Integrations.IncomingInvoice.invoiceSelect,
      {
        vendor: INTEGRATIONS.agVend
      }
    );
    goForward();
  };

  const handleFilter = searchValue => {
    setSearchText(searchValue);
  };

  return (
    <>
      <div className="w-full mx-6">
        <div
          className="flex flex-col bg-white top-30 left-26 rounded-md p-6 shadow-sm"
          data-testid="agvend-invoice-selection"
        >
          <div className="mb-4">
            <div className="flex flex-col">
              <div className="text-lg leading-7 font-semibold text-neutral-1000 mb-2">
                Select Invoices
              </div>
              <p className="text-sm leading-6 text-neutral-70">
                Select the Cropwise crop season you would like to import into
                and your AgVend supplied invoices.
              </p>
            </div>
            <div className="grid grid-cols-2 gap-x-2 text-sm">
              <Editor label="Cropwise Financials Crop Season*" halfWidth>
                <div className="text-sm">
                  <CropSeasonSingleSelectionDropdown
                    id="cwfCropSeason"
                    value={selectedCropSeasons ? selectedCropSeasons[0] : null}
                    onChange={value => {
                      handleCropSeasonSelect(value);
                    }}
                    createNewCropSeasonEnabled
                  />
                </div>
              </Editor>
            </div>
          </div>
        </div>
        <div className="flex flex-row mt-4 mb-1 items-center">
          <div className="flex flex-row">
            <div className="mr-4 relative inline-block min-w-360px base-drop-down-search-input">
              <div className="prefix-icon">
                <img alt="Search icon" src={SearchIcon} />
              </div>
              <Input
                id="label-search-input"
                data-testid="search-input"
                placeholder="Search by invoice #, retailer, or products"
                allowClear
                disabled={false}
                type="default"
                autoComplete="off"
                onChange={e => handleFilter(e.target.value)}
              />
            </div>

            <div className="my-auto">
              <Switch
                size="medium"
                checked={includePreviousImported}
                onChange={newValue => {
                  setIncludePreviousImported(newValue);
                }}
              />
              <span
                className={`ml-2 ${!includePreviousImported &&
                  'text-gray-500'} `}
              >
                Display previously imported invoices
              </span>
            </div>
          </div>
          <div className="w-40 ml-auto text-right">
            <span
              className={`${
                selectedInvoices?.length > 0 ? 'text-blue-50' : 'text-gray-500'
              } font-bold`}
            >
              {`${selectedInvoices?.length ?? 0} ${
                selectedInvoices?.length > 1 ? 'records' : 'record'
              } selected`}
            </span>
          </div>
        </div>
        <div
          className="flex flex-col bg-white top-30 left-26 rounded-md mb-24 shadow-sm"
          data-testid="agvend-invoice-list"
        >
          <AgVendInvoiceTable
            filteredInvoices={filteredInvoices}
            handleSelection={handleSelectInvoice}
            selectedInvoices={selectedInvoices}
            isLoading={isLoading}
            isCropSeasonSelected={selectedCropSeasons?.length > 0}
            PaginationComponent={() =>
              CwFPagination(
                totalElements,
                currentPage - 1,
                page => setCurrentPage(page + 1),
                null,
                pageSize
              )
            }
          />
        </div>
      </div>
      <SubmissionFooter
        nextDisabled={!selectedInvoices?.length || !selectedCropSeasons?.length}
        onNext={onNextHandler}
        isLastStep={false}
        context={AgVendContext}
        nextLabel="Next: Match with AgVend"
        integrationType={INTEGRATIONS.agVend}
        isBottomFixed
      />
    </>
  );
};

export default AgVendInvoiceList;
