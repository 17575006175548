/* eslint-disable import/prefer-default-export */
import { convert } from '@agconnections/agc_unitconversion';
import Decimal from 'decimal.js';
import { isNumber } from 'lodash';
import { DEFAULT_ROUND, Maths } from './maths';
import {
  AreaStdUnits,
  AreaOtherUnits,
  AreaAllUnits,
  MassAndWeightStdUnits,
  MassAndWeightOtherUnits,
  MassAndWeightAllUnits,
  VolumeStdUnits,
  VolumeOtherUnits,
  VolumeAllUnits,
  getCorrectUnitAbbreviation,
  getCorrectUnitName,
  ImperialUnits,
  MetricUnits
} from './unitsAbbreviation';
import alphanumSort from './sorters';

export const specialCaseUnit = [
  'bag',
  'box',
  'bulk',
  'bushel',
  'can',
  'crate',
  'dispenser',
  'kernal',
  'kernel',
  'load',
  'package',
  'plant',
  'roll',
  'seed',
  'tablet',
  'tray',
  'unit'
];

const invalidUnits = ['kg', 'feet', 'cubic feet', 'square feet'];
const SERVICE_UNITS = ['second', 'hour', 'day', 'year'];

/*
  https://www.omnicalculator.com/physics/specific-gravity
  we are expecting the density is in grams per cubic centimeter g/cm3
*/

const otherUnits = [
  ...AreaOtherUnits,
  ...MassAndWeightOtherUnits,
  ...VolumeOtherUnits
];
const stdUnits = [...AreaStdUnits, ...MassAndWeightStdUnits, ...VolumeStdUnits];

const calculateSpecificGravity = density => (density ? density / 1.0 : 0);

const cleanCompatibleUnits = possibilities => {
  const possibleUnitConversions = [];

  possibilities.forEach(unit => {
    if (!unit.endsWith('s') && !invalidUnits.includes(unit.toLowerCase())) {
      possibleUnitConversions.push(unit);
    }
  });

  return possibleUnitConversions;
};
export const sortingImperialUnitsAndMetricUnits = units => {
  const imperialUnitValues = [];
  const metricUnitValues = [];
  const otherGenericUnits = [];

  units.forEach(unit => {
    const imperialUnitExist = ImperialUnits.indexOf(unit.key) !== -1;
    const metricUnitExist = MetricUnits.indexOf(unit.key) !== -1;

    if (imperialUnitExist) {
      imperialUnitValues.push(unit);
    }
    if (metricUnitExist) {
      metricUnitValues.push(unit);
    }
    if (!imperialUnitExist && !metricUnitExist) {
      otherGenericUnits.push(unit);
    }
  });
  return imperialUnitValues.concat(metricUnitValues, otherGenericUnits);
};
const unitAbbreviation = units => {
  const transformedUnits = units.map(unit => ({
    key: unit,
    value: getCorrectUnitAbbreviation(unit)
  }));

  const valueMap = transformedUnits.reduce((acc, item) => {
    if (!acc[item.value]) {
      acc[item.value] = [];
    }
    acc[item.value].push(item.key);
    return acc;
  }, {});

  const uniqueUnits = transformedUnits.filter(item => {
    const relatedKeys = valueMap[item.value];
    const hasDuplicate = relatedKeys.length > 1;
    const isInOtherUnits = otherUnits.includes(item.key);
    const isInStdUnits = stdUnits.includes(item.key);
    return !(hasDuplicate && isInOtherUnits && !isInStdUnits);
  });
  return sortingImperialUnitsAndMetricUnits(alphanumSort(uniqueUnits, 'value'));
};

const removeDuplicates = units => [...new Set(units)];

export const getCompatibleUnits = (units, density) => {
  const mainUnit = units[0];
  const packageUnit = units[1];
  const possibilities = [
    getCorrectUnitName(mainUnit),
    getCorrectUnitName(packageUnit)
  ];
  if (AreaAllUnits.includes(mainUnit) && AreaAllUnits.includes(packageUnit)) {
    possibilities.push(...AreaStdUnits);
    return unitAbbreviation(removeDuplicates(possibilities));
  }

  if (SERVICE_UNITS.includes(mainUnit)) {
    possibilities.push(...SERVICE_UNITS);
    return unitAbbreviation(removeDuplicates(possibilities));
  }

  const isVolume = VolumeAllUnits.includes(mainUnit);
  const isMass = MassAndWeightAllUnits.includes(mainUnit);
  // Handle standard conversion
  if (density > 0 && (isVolume || isMass)) {
    possibilities.push(...VolumeStdUnits, ...MassAndWeightStdUnits);
  } else if (isVolume) {
    possibilities.push(...VolumeStdUnits);
  } else if (isMass) {
    possibilities.push(...MassAndWeightStdUnits);
  } else {
    const convertUnits = convert(0, calculateSpecificGravity(density))
      .from(mainUnit)
      .possibilities();
    possibilities.push(...cleanCompatibleUnits(convertUnits));
  }

  return unitAbbreviation(removeDuplicates(possibilities));
};

export const roundValue = valueToRound =>
  Math.round((valueToRound + Number.EPSILON) * 100) / 100;

export const getConvertUnitFromTo = (
  value,
  fromUnit,
  toUnit,
  { density, productDensity, stdfactor, stdunit, stdpackageunit } = {}
) => {
  if (value === 0) return { Value: 0 };

  if (!value || !fromUnit || !toUnit) {
    return { Value: 1 }; // ????
  }

  if (fromUnit === toUnit) return { Value: value };

  const actualDensity = density || productDensity;

  if (specialCaseUnit.includes(stdpackageunit)) {
    if (fromUnit === stdpackageunit) {
      const valueInStdUnit = Maths.multiplyRound(
        value,
        stdfactor,
        DEFAULT_ROUND,
        Decimal.ROUND_HALF_UP
      );
      if (toUnit === stdunit) {
        return { Value: valueInStdUnit };
      }
      const conversion = convert(valueInStdUnit, actualDensity)
        .from(getCorrectUnitName(stdunit))
        .to(getCorrectUnitName(toUnit))
        .toJSON();
      return conversion;
    }
    if (toUnit === stdpackageunit) {
      if (fromUnit === stdunit) {
        return {
          Value: Maths.divideSafeRound(
            value,
            stdfactor,
            DEFAULT_ROUND,
            Decimal.ROUND_HALF_UP
          )
        };
      }
      const conversionToStdUnit = convert(value, actualDensity)
        .from(getCorrectUnitName(fromUnit))
        .to(getCorrectUnitName(stdunit))
        .toJSON();
      return {
        Value: Maths.divideSafeRound(
          conversionToStdUnit.Value,
          stdfactor,
          DEFAULT_ROUND,
          Decimal.ROUND_HALF_UP
        )
      };
    }
  }

  return convert(value, actualDensity)
    .from(getCorrectUnitName(fromUnit))
    .to(getCorrectUnitName(toUnit))
    .toJSON();
};

export const setConversionFactor = (areSame, valueToInvert) =>
  areSame ? 1 / valueToInvert : valueToInvert;

export const calcSpecialCase = (
  unitIsSame,
  totProduct,
  rate,
  conversionFactor,
  unitIsPackageUnit
) => {
  let newValue = 0;
  const newConversionFactor = !unitIsSame
    ? setConversionFactor(unitIsPackageUnit, conversionFactor)
    : 1;
  if (rate > 0) {
    newValue = !unitIsSame
      ? totProduct / newConversionFactor / rate
      : totProduct / rate;
  }

  return newValue;
};

export const unitsAreSame = (firstUnit, secondUnit) => firstUnit === secondUnit;

export const calcConversions = (
  convertValue,
  fromUnit,
  toUnit,
  bothUnitsAreSame,
  product
) => {
  let converted = 0;
  if (bothUnitsAreSame) {
    converted = convertValue;
  } else {
    const totalConversion = getConvertUnitFromTo(
      Number.parseFloat(convertValue),
      fromUnit,
      toUnit,
      product
    );
    converted = totalConversion?.Value;
  }

  return converted;
};

const byRatePerArea = ['ratePerAreaValue', 'ByRatePerArea'];
const byTotalProduct = ['totalProductValue', 'ByTotalProduct'];
const byRatePerTank = ['ratePerTankValue', 'ByRatePerTank'];

export const calculateProductValues = (
  valueChanged,
  targetValue,
  changedUnits,
  changedAppArea,
  newPrice,
  productToAdd = {},
  values = {}
) => {
  let totalProduct = 0;
  let ratePerTank = 0;
  let ratePerArea = 0;
  let totalCost = 0;
  const appArea = changedAppArea?.changedArea || productToAdd.appliedAreaValue;
  const appPct = changedAppArea?.changedPct || productToAdd.coveragePercent;
  const rateUnitValue = changedUnits?.rateUnit || productToAdd.ratePerAreaUnit;
  const totUnitValue =
    changedUnits?.totalProdUnit || productToAdd.totalProductUnit;
  const tankUnitValue = changedUnits?.tankUnit || productToAdd.ratePerTankUnit;
  const priceBag = newPrice || productToAdd.averagePriceAtTimeOfCreation;
  const tankCount = values.tankInformation?.tankCount;

  const rateUnitIsPackageUnit = unitsAreSame(
    rateUnitValue,
    productToAdd.stdpackageunit
  );

  const tankUnitIsPackageUnit = unitsAreSame(
    tankUnitValue,
    productToAdd.stdpackageunit
  );

  const totalUnitIsPackageUnit = unitsAreSame(
    totUnitValue,
    productToAdd.stdpackageunit
  );

  const totalUnitIsUnitPrice = unitsAreSame(
    totUnitValue,
    productToAdd.averagePriceUnitAtTimeOfCreation
  );

  const specialCase = specialCaseUnit.includes(productToAdd.stdpackageunit);
  let conversionFromRateToStd = 1;
  let conversionFromTankToStd = 1;
  const actualDensity = productToAdd.density || productToAdd.productDensity;

  if (
    !specialCase &&
    !specialCaseUnit.includes(rateUnitValue) &&
    !specialCaseUnit.includes(tankUnitValue)
  ) {
    const conversion = convert(1, actualDensity);
    conversionFromRateToStd = conversion
      .from(getCorrectUnitName(productToAdd.stdunit))
      .to(getCorrectUnitName(rateUnitValue))
      .toJSON()._value;
    conversionFromTankToStd = conversion
      .from(getCorrectUnitName(productToAdd.stdunit))
      .to(getCorrectUnitName(tankUnitValue))
      .toJSON()._value;
  }

  const conversionFactor = productToAdd.stdfactor;
  const rateUnitConversionFactor = conversionFactor * conversionFromRateToStd;
  const tankUnitConversionFactor = conversionFactor * conversionFromTankToStd;

  if (byRatePerArea.includes(valueChanged)) {
    const totalUnitIsSame = unitsAreSame(rateUnitValue, totUnitValue);
    const tankUnitIsSame = unitsAreSame(totUnitValue, tankUnitValue);
    const totalProd = targetValue * appArea;

    if (specialCase) {
      totalProduct = calcSpecialCase(
        totalUnitIsSame,
        totalProd,
        1,
        rateUnitConversionFactor,
        rateUnitIsPackageUnit
      );
      if (
        rateUnitValue !== productToAdd.stdpackageunit &&
        rateUnitValue !== productToAdd.stdunit &&
        appArea > 0
      ) {
        totalProduct = convert(totalProduct, actualDensity)
          .from(getCorrectUnitName(rateUnitValue))
          .to(getCorrectUnitName(productToAdd.stdunit))
          .toJSON().Value;
      }
      ratePerTank = calcSpecialCase(
        tankUnitIsSame,
        totalProduct,
        tankCount,
        tankUnitConversionFactor,
        totalUnitIsPackageUnit
      );
    } else {
      totalProduct = calcConversions(
        totalProd,
        rateUnitValue,
        totUnitValue,
        totalUnitIsSame,
        productToAdd
      );

      if (tankCount > 0) {
        const rateTank = totalProduct / tankCount;
        ratePerTank = calcConversions(
          rateTank,
          rateUnitValue,
          tankUnitValue,
          tankUnitIsSame
        );
      }
    }

    ratePerArea = targetValue;
  }

  if (byTotalProduct.includes(valueChanged)) {
    const rateUnitIsSame = unitsAreSame(totUnitValue, rateUnitValue);
    const tankUnitIsSame = unitsAreSame(totUnitValue, tankUnitValue);

    if (specialCase) {
      ratePerArea = calcSpecialCase(
        rateUnitIsSame,
        targetValue,
        appArea,
        rateUnitConversionFactor,
        totalUnitIsPackageUnit
      );

      ratePerTank = calcSpecialCase(
        tankUnitIsSame,
        targetValue,
        tankCount,
        tankUnitConversionFactor,
        totalUnitIsPackageUnit
      );
    } else {
      if (appArea > 0) {
        const rateArea = targetValue / appArea;

        ratePerArea = calcConversions(
          rateArea,
          totUnitValue,
          rateUnitValue,
          rateUnitIsSame,
          productToAdd
        );
      }

      if (tankCount > 0) {
        const rateTank = targetValue / tankCount;

        ratePerTank = calcConversions(
          rateTank,
          totUnitValue,
          tankUnitValue,
          tankUnitIsSame,
          productToAdd
        );
      }
    }

    totalProduct = targetValue;
  }

  if (byRatePerTank.includes(valueChanged)) {
    if (tankCount > 0) {
      const rateUnitIsSame = unitsAreSame(tankUnitValue, rateUnitValue);
      const totalUnitIsSame = unitsAreSame(tankUnitValue, totUnitValue);
      const totProd = targetValue * tankCount;

      if (specialCase) {
        ratePerArea = calcSpecialCase(
          rateUnitIsSame,
          totProd,
          appArea,
          rateUnitConversionFactor,
          tankUnitIsPackageUnit
        );

        totalProduct = calcSpecialCase(
          totalUnitIsSame,
          totProd,
          1,
          rateUnitConversionFactor,
          tankUnitIsPackageUnit
        );
      } else {
        if (appArea > 0) {
          const rateArea = totProd / appArea;

          ratePerArea = calcConversions(
            rateArea,
            tankUnitValue,
            rateUnitValue,
            rateUnitIsSame,
            productToAdd
          );
        }

        totalProduct = calcConversions(
          totProd,
          tankUnitValue,
          totUnitValue,
          totalUnitIsSame,
          productToAdd
        );
      }
    }

    ratePerTank = targetValue;
  }

  if (totalUnitIsUnitPrice) {
    totalCost = totalProduct * priceBag;
  } else {
    const totalProductConverted = calcConversions(
      totalProduct,
      totUnitValue,
      productToAdd?.averagePriceUnitAtTimeOfCreation || productToAdd.stdunit,
      totalUnitIsUnitPrice,
      productToAdd
    );
    totalCost = totalProductConverted * priceBag;
  }

  return {
    ...productToAdd,
    totalProductValue: isNumber(totalProduct)
      ? Maths.parseFloatRound(
          totalProduct,
          DEFAULT_ROUND,
          Decimal.ROUND_HALF_UP
        )
      : totalProduct,
    ratePerAreaValue: isNumber(ratePerArea)
      ? Maths.parseFloatRound(ratePerArea, DEFAULT_ROUND, Decimal.ROUND_HALF_UP)
      : ratePerArea,
    ratePerTankValue: isNumber(ratePerTank)
      ? Maths.parseFloatRound(ratePerTank, DEFAULT_ROUND, Decimal.ROUND_HALF_UP)
      : ratePerTank,
    totalCostAtTimeOfCreation: isNumber(totalCost)
      ? Maths.parseFloatRound(totalCost, DEFAULT_ROUND, Decimal.ROUND_HALF_UP)
      : totalCost,
    averagePriceAtTimeOfCreation: priceBag,
    ratePerAreaUnit: rateUnitValue,
    ratePerTankUnit: tankUnitValue,
    totalProductUnit: totUnitValue,
    appliedAreaValue: appArea,
    coveragePercent: appPct
  };
};

export const calculateNumberOfApplications = (
  productToAdd,
  numberOfApps = 1
) => {
  const {
    appliedAreaValue,
    ratePerAreaValue,
    totalProductUnit,
    averagePriceAtTimeOfCreation,
    stdunit,
    stdpackageunit,
    stdfactor,
    density,
    productDensity,
    ratePerAreaUnit
  } = productToAdd;

  let totalProductValue = ratePerAreaValue * appliedAreaValue * numberOfApps;

  if (ratePerAreaUnit !== stdunit) {
    const conversionToStdUnit = getConvertUnitFromTo(
      totalProductValue,
      ratePerAreaUnit,
      stdunit,
      { density, productDensity, stdfactor, stdunit, stdpackageunit }
    ).Value;
    totalProductValue = conversionToStdUnit;
  }

  if (stdunit !== totalProductUnit) {
    const conversionToTotalProductUnit = getConvertUnitFromTo(
      totalProductValue,
      stdunit,
      totalProductUnit,
      { density, productDensity, stdfactor, stdunit, stdpackageunit }
    ).Value;
    totalProductValue = conversionToTotalProductUnit;
  }

  const totalCostAtTimeOfCreation =
    totalProductValue * averagePriceAtTimeOfCreation;

  return {
    ...productToAdd,
    applicationCount: numberOfApps,
    totalProductValue: roundValue(totalProductValue),
    totalCostAtTimeOfCreation: roundValue(totalCostAtTimeOfCreation)
  };
};
