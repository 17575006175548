import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import { MapContext } from 'react-mapbox-gl';
import { center, points } from '@turf/turf';
import { featureCollection } from '@turf/helpers';
import { useLocation } from 'react-router-dom';
import { flatMap, debounce, isEqual } from 'lodash';

import { Context } from 'components/Store';
import { getUpdatedBoundingBox } from 'screens/Property/helpers/propertyDataHelpers';
import {
  DEFAULT_ORG_MAP_VIEW,
  DEFAULT_ZOOM,
  LAST_COORDINATES_STORED,
  NATIONAL_ZOOM,
  PROPERTY_LANDING_MAP_CORDS,
  USA_CENTER_COORDINATES
} from 'screens/Property/helpers/constants';
import {
  SET_MAP_PRINTABLE_AREA_BBOX,
  IS_MAP_MOVING,
  SET_MAP_LEGEND_LABELS
} from 'reducers/reducer';
import {
  calculatePrintableAreaBoundingBox,
  getCenterOfBoundingBox,
  PRINTABLE_AREA_PADDING
} from 'screens/Property/helpers/mapApiHelpers';
import createAction from 'helpers/createAction';
import getAllCoordinates from 'screens/Property/PropertiesLanding/PropertyCreate/helpers.js/getAllCoordinates';
import PropertyMapContext from '../../PropertyMapContext';
import {
  buildCustomLabel,
  CUSTOM_LAYER_TYPE,
  FOCUSED_LAYER_TYPE,
  hasCustomLabel,
  isFeatureSelected,
  SELECTED_LAYER_TYPE
} from '../PropertyMap/utilities/mapboxLayerHelpers';
import SelectedLayer from './components/SelectedLayer/SelectedLayer';
import DynamicLayer from './components/DynamicLayer';
import CustomLayers from './components/CustomLayers';
import { editRegex, createRegex } from '../constants';

const PropertyFeatureLayer = ({
  geoJSON,
  customStyles,
  visible,
  setZoom,
  activeObjectType,
  isGeoJSONLabelEnabled,
  currentCoordinates,
  allFFTGeoJson
}) => {
  const { pathname, state } = useLocation();

  const [
    {
      selectedProperty,
      loadingProperties,
      loadTimestamp,
      fieldsToMassiveAssign,
      isExportPDFMapsActive,
      isMassAssignerActive,
      exportPDFMapsStep,
      mapPrintableAreaBbox,
      cropColors,
      mapLabelConfig
    },
    dispatch
  ] = useContext(Context);
  const [boundingBox, setBoundingBox] = useState();
  const [mapIsReady, setMapIsReady] = useState(false);
  const isMultipleSelect = isExportPDFMapsActive;

  const isAnimateActive = useRef(false);

  const [filteredGeoJSON, setFilteredGeoJSON] = useState(null);

  useEffect(() => {
    if (
      (editRegex.test(pathname) || createRegex.test(pathname)) &&
      allFFTGeoJson?.features?.length
    ) {
      setFilteredGeoJSON({
        type: 'FeatureCollection',
        features: allFFTGeoJson.features.filter(feature => feature?.geometry)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const selectedJSON = useMemo(
    () =>
      featureCollection(
        geoJSON?.features?.filter(
          ({ properties }) => properties.$layer === SELECTED_LAYER_TYPE
        ) || []
      ),
    [geoJSON]
  );

  const taggedGeoJSON = useMemo(() => {
    const sourceGeoJSON = filteredGeoJSON || geoJSON;
    if (
      !isMultipleSelect &&
      !isMassAssignerActive &&
      fieldsToMassiveAssign.length === 0
    ) {
      return sourceGeoJSON;
    }
    // Start labelLegendKey at number of unique fields and cropzones + 1 to avoid displaying 0 to the user
    let labelLegendKey = fieldsToMassiveAssign.reduce((count, field) => {
      return count + (field.cropzones.length ? field.cropzones.length : 1);
    }, 1);
    const taggedFeatures = sourceGeoJSON.features
      .filter(
        ({ properties, geometry }) => properties?.id && geometry?.coordinates
      )
      .map(feature => {
        const featureProps = { ...feature.properties };
        const isCustom =
          Object.keys(customStyles ?? {}).includes(featureProps.$layer) ||
          isFeatureSelected(fieldsToMassiveAssign, feature);
        const hasCrop = !!featureProps.crop?.id;

        if (featureProps.$landType === 'field') {
          const selectedField = fieldsToMassiveAssign.find(
            field => field.id === featureProps.id
          );
          if (selectedField) {
            featureProps.farmId = selectedField.farmId;
            featureProps.farmName = selectedField.farmName;
          }
        }

        const customLabel = buildCustomLabel(
          featureProps,
          mapLabelConfig,
          fieldsToMassiveAssign,
          exportPDFMapsStep === 0
        );

        if (
          hasCustomLabel(feature) &&
          isFeatureSelected(fieldsToMassiveAssign, feature)
        ) {
          labelLegendKey -= 1;
        }

        return {
          ...feature,
          properties: {
            ...featureProps,
            cropColor:
              isCustom && hasCrop && exportPDFMapsStep === 1
                ? cropColors[featureProps.crop.id]
                : undefined,
            $layer: isCustom ? CUSTOM_LAYER_TYPE : featureProps.$layer,
            customLabel,
            labelLegendKey: hasCustomLabel(feature) ? labelLegendKey : undefined
          }
        };
      });

    return featureCollection(taggedFeatures);
  }, [
    filteredGeoJSON,
    geoJSON,
    customStyles,
    cropColors,
    isMultipleSelect,
    fieldsToMassiveAssign,
    exportPDFMapsStep,
    mapLabelConfig,
    isMassAssignerActive
  ]);

  const customJSONArr = useMemo(
    () =>
      Object.keys(customStyles ?? {}).map(key =>
        (taggedGeoJSON.features || []).filter(
          ({ properties }) =>
            properties.$layer === key || properties.$layer === CUSTOM_LAYER_TYPE
        )
      ),
    [taggedGeoJSON, customStyles]
  );

  const map = useContext(MapContext);

  if (!mapIsReady && map && !map.isMoving() && map.isStyleLoaded()) {
    setMapIsReady(true);
  }

  useEffect(() => {
    if (!taggedGeoJSON?.features || exportPDFMapsStep !== 1) return;
    const newMapLegendLabels = {};
    taggedGeoJSON.features.forEach(feature => {
      if (
        hasCustomLabel(feature) &&
        isFeatureSelected(fieldsToMassiveAssign, feature)
      ) {
        newMapLegendLabels[feature.properties.labelLegendKey] =
          feature.properties.customLabel;
      }
    });
    createAction(dispatch, SET_MAP_LEGEND_LABELS, newMapLegendLabels);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportPDFMapsStep, mapLabelConfig]);

  useEffect(() => {
    if (!map) return;
    const handleIdle = () => {
      if (!map.isMoving()) {
        if (pathname !== '/app/property-landing') return;
        const _center = map.getCenter();
        const zoom = map.getZoom();
        localStorage.setItem(
          DEFAULT_ORG_MAP_VIEW,
          JSON.stringify({ coordinates: [_center.lng, _center.lat], zoom })
        );
        map.off('idle', handleIdle);
      }
    };
    map.on('idle', handleIdle);
    // eslint-disable-next-line consistent-return
    return () => {
      map.off('idle', handleIdle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  const handleZooming = useCallback(
    bounds => {
      map.fitBounds(bounds, {
        padding: 160,
        animate: isAnimateActive.current
      });
      setBoundingBox(bounds);
      setZoom([map.getZoom()]);
    },
    [map, setZoom, isAnimateActive]
  );

  const flyToCoordinates = (coordinates, zoom) => {
    map.flyTo({
      center: coordinates,
      zoom,
      animate: false
    });
  };

  const handleSelectedProperty = () => {
    const { parentFarm } = state || [];
    const allCoordinates = getAllCoordinates(
      selectedProperty,
      parentFarm
    ).filter(coordinate => coordinate);
    const featureCollection_ = {
      type: 'FeatureCollection',
      features: allCoordinates.map(coord => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: coord
        }
      }))
    };
    const bounds = getUpdatedBoundingBox(undefined, featureCollection_, false);
    map.fitBounds(bounds, {
      padding: 160,
      animate: isAnimateActive.current
    });
    setBoundingBox(bounds);
    setZoom([map.getZoom()]);
  };

  const handleDefaultOrgView = () => {
    const defaultView = JSON.parse(localStorage.getItem(DEFAULT_ORG_MAP_VIEW));
    if (defaultView) {
      flyToCoordinates(defaultView.coordinates, defaultView.zoom);
    } else {
      flyToCoordinates(USA_CENTER_COORDINATES, NATIONAL_ZOOM);
    }
  };

  const handleNewOrgView = () => {
    const noLayers = (filteredGeoJSON || geoJSON).features.length === 0;
    if (
      state?.isNewOrg &&
      Object.keys(selectedProperty).length === 0 &&
      noLayers &&
      currentCoordinates
    ) {
      if (
        isEqual(
          currentCoordinates?.coordinates ?? currentCoordinates,
          USA_CENTER_COORDINATES
        ) &&
        !currentCoordinates?.userGeolocation
      ) {
        flyToCoordinates(USA_CENTER_COORDINATES, NATIONAL_ZOOM);
      } else if (
        currentCoordinates?.coordinates &&
        currentCoordinates?.userGeolocation
      ) {
        flyToCoordinates(currentCoordinates.userGeolocation, DEFAULT_ZOOM);
      }
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (isExportPDFMapsActive) {
      return;
    }

    const noLayers = (filteredGeoJSON || geoJSON).features.length === 0;
    const isPropertyLanding = pathname === '/app/property-landing';

    if (isPropertyLanding && loadingProperties) return;
    if (isPropertyLanding && noLayers) {
      flyToCoordinates(USA_CENTER_COORDINATES, NATIONAL_ZOOM);
      return;
    }

    if (
      state &&
      (selectedProperty?.fields?.length ||
        state?.parentFarm?.fields?.length ||
        selectedProperty?.fieldId)
    ) {
      handleSelectedProperty();
      return;
    }

    if (loadingProperties) return;

    const hasHandledNewOrg = handleNewOrgView();
    if (hasHandledNewOrg) return;

    if (!state?.isNewOrg && Object.keys(selectedProperty).length === 0) {
      handleDefaultOrgView();
      return;
    }

    if (noLayers) {
      flyToCoordinates(USA_CENTER_COORDINATES, NATIONAL_ZOOM);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // adjust the bounding box so that the current zoom level can be determined
  useEffect(() => {
    const selectedIds = flatMap(
      fieldsToMassiveAssign.map(field => {
        const cropZoneIds =
          field?.cropzones?.map(cropzone => cropzone.id) ?? [];
        return [field.id, ...cropZoneIds];
      })
    );

    const focusedGeoJSONFeatures = geoJSON?.features.filter(feature => {
      if (
        !isMultipleSelect &&
        !isMassAssignerActive &&
        fieldsToMassiveAssign.length === 0
      ) {
        return feature?.properties?.$layer === FOCUSED_LAYER_TYPE;
      }
      return selectedIds.includes(feature?.properties?.id);
    });

    const fc = focusedGeoJSONFeatures.length
      ? featureCollection(focusedGeoJSONFeatures)
      : geoJSON;

    const newBounds = getUpdatedBoundingBox(
      boundingBox,
      fc,
      focusedGeoJSONFeatures.length
    );

    if (fc && newBounds && mapIsReady && !map.isMoving()) {
      if (newBounds.find(bound => bound === Infinity || bound === -Infinity)) {
        return;
      }

      const newLng = newBounds.slice(0, 2);
      const newLat = newBounds.slice(-2);

      const feature = points([newLng, newLat]);
      const centroid = center(feature);

      localStorage.setItem(
        LAST_COORDINATES_STORED,
        JSON.stringify(centroid.geometry.coordinates)
      );

      if (
        isExportPDFMapsActive &&
        (exportPDFMapsStep === 1 || !boundingBox) &&
        mapPrintableAreaBbox
      ) {
        if (!isEqual(mapPrintableAreaBbox.bbox, boundingBox)) {
          const oldContainer = map.getContainer();
          const newWidth = oldContainer.clientWidth - PRINTABLE_AREA_PADDING;
          const oldWidth =
            mapPrintableAreaBbox.containerWidth - PRINTABLE_AREA_PADDING;
          const oldZoom = map.getZoom();
          // Calculate the zoom offset based on the width ratio
          const zoomOffset = Math.log2(newWidth / oldWidth);
          const newZoom = oldZoom + zoomOffset;

          map.jumpTo({
            center: getCenterOfBoundingBox(mapPrintableAreaBbox.bbox),
            zoom: newZoom
          });
          setBoundingBox(mapPrintableAreaBbox.bbox);
        }
      } else if (
        !(
          mapPrintableAreaBbox?.prevStep === 1 &&
          exportPDFMapsStep === 0 &&
          isEqual(
            mapPrintableAreaBbox.fieldsToMassiveAssign,
            fieldsToMassiveAssign
          )
        )
      ) {
        handleZooming(newBounds);
      }
    }
  }, [
    boundingBox,
    filteredGeoJSON,
    geoJSON,
    map,
    mapIsReady,
    isMultipleSelect,
    fieldsToMassiveAssign,
    taggedGeoJSON?.features,
    isExportPDFMapsActive,
    exportPDFMapsStep,
    mapPrintableAreaBbox,
    isMassAssignerActive,
    handleZooming
  ]);

  useEffect(() => {
    if (loadingProperties) {
      setBoundingBox(undefined);
    }
  }, [loadingProperties, loadTimestamp]);

  // wait for the bounding box movement to stop then set zoom to the map's current zoom value
  useEffect(() => {
    let mounted = true;

    const handleMove = debounce(async () => {
      // await set the zoom based on the map's new zoom level
      setZoom(() => {
        const newZoom = map.getZoom();
        return [newZoom];
      });
      if (exportPDFMapsStep !== 1) {
        const mapWidth = map.getContainer().clientWidth;
        createAction(dispatch, SET_MAP_PRINTABLE_AREA_BBOX, {
          containerWidth: mapWidth,
          bbox: calculatePrintableAreaBoundingBox(map),
          prevStep: 1,
          fieldsToMassiveAssign
        });
      } else {
        createAction(dispatch, SET_MAP_PRINTABLE_AREA_BBOX, {
          ...mapPrintableAreaBbox,
          prevStep: 0
        });
      }
      createAction(dispatch, IS_MAP_MOVING, map.isMoving());
    }, 50);

    const moveChecker = debounce(() => {
      if (mounted) {
        setZoom(() => {
          const newZoom = map.getZoom();
          return [newZoom];
        });
        const zoom = map.getZoom();
        const _center = map.getCenter();
        const coordinates = [_center.lng, _center.lat];
        localStorage.setItem(
          PROPERTY_LANDING_MAP_CORDS,
          JSON.stringify({ coordinates, zoom })
        );
        createAction(dispatch, IS_MAP_MOVING, false);
      }
    }, 300);

    if (map && pathname === '/app/property-landing') {
      map.on('moveend', moveChecker);
    }
    if (map && isExportPDFMapsActive && exportPDFMapsStep === 0) {
      map.on('move', handleMove);
      map.on('drag', handleMove);
      map.on('dragend', handleMove);
      map.on('zoom', handleMove);
    }

    return () => {
      mounted = false;
      if (map) {
        map.off('moveend', moveChecker);
        map.off('move', handleMove);
        map.off('drag', handleMove);
        map.off('zoom', handleMove);
      }
      moveChecker.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    map,
    boundingBox,
    setZoom,
    pathname,
    dispatch,
    exportPDFMapsStep,
    isExportPDFMapsActive
  ]);

  let labelKey;
  if (isMultipleSelect) {
    labelKey = 'name';
  } else if (activeObjectType === 'field' || activeObjectType === 'cropzone') {
    labelKey = 'fieldAndCropZoneLabel';
  } else {
    labelKey = 'defaultLabel';
  }

  const showLegendLabels =
    exportPDFMapsStep === 1 && mapLabelConfig.labelLocation === 'legend';

  if (!geoJSON || !visible) {
    return null;
  }
  return (
    <PropertyMapContext.Consumer>
      {({ onClickShape }) => (
        <>
          <SelectedLayer onClickShape={onClickShape} geoJSON={selectedJSON} />
          <DynamicLayer
            onClickShape={onClickShape}
            geoJSON={taggedGeoJSON}
            labelKey={labelKey}
            isGeoJSONLabelEnabled={isGeoJSONLabelEnabled}
          />
          {customStyles && !createRegex.test(pathname) && (
            <CustomLayers
              geoJSONArr={customJSONArr}
              stylesArr={Object.values(customStyles)}
              onClickShape={onClickShape}
              isMultipleSelect={isMultipleSelect}
              showLegendLabels={showLegendLabels}
            />
          )}
        </>
      )}
    </PropertyMapContext.Consumer>
  );
};

PropertyFeatureLayer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  geoJSON: PropTypes.object,
  customStyles: PropTypes.objectOf(
    PropTypes.shape({
      fillColor: PropTypes.string,
      fillOutlineColor: PropTypes.string
    })
  ),
  visible: PropTypes.bool,
  setZoom: PropTypes.func,
  activeObjectType: PropTypes.string.isRequired,
  isGeoJSONLabelEnabled: PropTypes.bool.isRequired,
  allFFTGeoJson: PropTypes.shape(),
  currentCoordinates: PropTypes.shape({
    coordinates: PropTypes.arrayOf(PropTypes.number),
    userGeolocation: PropTypes.arrayOf(PropTypes.number)
  })
};

PropertyFeatureLayer.defaultProps = {
  currentCoordinates: null,
  geoJSON: null,
  allFFTGeoJson: { features: [] },
  customStyles: null,
  visible: false,
  setZoom: () => {}
};

export default PropertyFeatureLayer;
