import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'syngenta-digital-cropwise-react-ui-kit';
import { Input } from '@agconnections/grow-ui';
import { ErrorMessage, useFormikContext } from 'formik';
import ProductTypeDisplay from 'screens/ProductList/helpers/ProductTypeDisplay';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import './index.css';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Context } from 'components/Store';
import { getConnectIntegrations } from 'screens/Integrations/helpers/processors';
import useProduct from 'hooks/useProduct';
import PriceUnitField from 'components/PriceUnitField';
// eslint-disable-next-line import/no-cycle
import { handleValidationAlertIcon } from '../CustomProductListForm';

const formRowClass = 'mb-6';
const formLabelClass = 'text-neutral-60';
const formValueClass = 'h-6 text-base leading-6';

const MasterListProductForm = ({ product }) => {
  const [{ organization }] = useContext(Context);
  const flags = useFlags();

  const hasConnectIntegration = getConnectIntegrations(flags, organization);

  const { values, setValues, setFieldValue, errors } = useFormikContext();
  const handleFieldChange = e => {
    setFieldValue(e.target.name, e.target.value);
  };
  const [listPriceUnits, setListPriceUnits] = useState([]);

  const { getMasterListProductUnits } = useProduct();

  const getStdUnit = () => {
    return product.stdUnit ? product.stdUnit : product.stdunit;
  };

  const getStdPackageUnit = () =>
    product.stdPackageUnit ?? product.stdpackageunit;
  useEffect(() => {
    if (product?.id || product?.productId) {
      getMasterListProductUnits(product.id || product.productId).then(units => {
        setValues({
          ...product,
          averagePricePerUnitUnit:
            product.averagePricePerUnitUnit || getStdPackageUnit()
        });
        setListPriceUnits(units);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValues, product]);

  const isSeedProduct = [product.producttype, product.productType].includes(
    'Seed'
  );

  return (
    <>
      <Row className={formRowClass} gutter={32}>
        <Col span={12}>
          <div className={formLabelClass}>Product Name</div>
          <div className={formValueClass}>
            {values.productName ?? values.name ?? '---'}
          </div>
        </Col>
        <Col span={12}>
          <div className={formLabelClass}>Manufacturer</div>
          <div className={formValueClass}>
            {values.manufacturerName ?? values.manufacturer ?? '---'}
          </div>
        </Col>
      </Row>
      <Row className={formRowClass} gutter={32}>
        <Col span={12}>
          <div className={formLabelClass}>Product Type</div>
          <Row className={formValueClass}>
            <ProductTypeDisplay
              productType={values.productType ?? values.producttype ?? '---'}
            />
          </Row>
        </Col>
        {!isSeedProduct && (
          <Col span={12}>
            <div className={formLabelClass}>EPA Number</div>
            <div className={formValueClass}>
              {values.registrationNumber?.length ||
              values.registrationnumber?.length
                ? values.registrationNumber ?? values.registrationnumber
                : '---'}
            </div>
          </Col>
        )}
      </Row>
      <Row className={formRowClass} gutter={32}>
        <Col span={12}>
          <PriceUnitField
            units={listPriceUnits}
            customLabel={<div className={formLabelClass}>List Price</div>}
            required={false}
            defaultUnit={values.averagePricePerUnitUnit || getStdPackageUnit()}
            fieldNameInput="averagePricePerUnit"
            fieldNameUnit="averagePricePerUnitUnit"
          />
          <ErrorMessage
            name="averagePricePerUnit"
            component="div"
            className="text-remove text-wrap"
          />
        </Col>
        <Col span={12}>
          <div className={formLabelClass}>Standard Unit</div>
          <div className={formValueClass}>{getStdUnit()}</div>
        </Col>
      </Row>
      {isSeedProduct && hasConnectIntegration && (
        <Row
          data-testid="package-weight-row"
          className={formRowClass}
          gutter={32}
        >
          <Col span={12}>
            <div className={formLabelClass}>Package Weight Density</div>
            <GrowUIFormField
              control={Input}
              name="packageWeight"
              value={
                values.packageWeight === 0 ? undefined : values.packageWeight
              }
              onChange={handleFieldChange}
              attachRight={
                <div
                  style={{ backgroundColor: 'white', borderLeft: 'none' }}
                  className="flex w-fit mr-10px"
                >
                  <span className="mr-1">seeds per lbs</span>
                  {handleValidationAlertIcon(errors, 'packageWeight')}
                </div>
              }
            />
          </Col>

          <Col span={12}>
            <div className={formLabelClass}>Standard Package Unit</div>
            <div data-testid="standard-package-unit" className={formValueClass}>
              {values.stdPackageUnit ?? values.stdpackageunit}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

MasterListProductForm.propTypes = {
  product: PropTypes.shape().isRequired
};

export default MasterListProductForm;
