import React, { useMemo, useContext, useState, useEffect, useRef } from 'react';
import { Table } from '@agconnections/grow-ui';
import ViewIcon from 'assets/open_in_new.svg';
import CwFPagination from 'components/CwFPagination';
import LandingTable from 'components/LandingTable';
import { format } from 'date-fns';
import tableSort from 'helpers/tableSort';
import PropTypes from 'prop-types';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { paths } from 'routes/paths';
import useProducts from 'hooks/useProducts';
import useInventoryInvoiceData from 'screens/Inventory/helpers/useInventoryInvoiceData';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import routesForPPLToCreate from 'screens/ProductList/helpers/creationRoutes';
import { Maths } from 'helpers/maths';
import formatNumber from 'helpers/formatNumber';
import { getConvertUnitFromTo } from 'helpers/unitConversionHelpers';
import ProductDetailTableEmptyState from '../ProductDetailTableEmptyState';

const PAGE_COUNT = 20;

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const ProductDetailInvoiceTable = ({ cropSeasons, dateRange }) => {
  const amplitude = useContext(AmplitudeContext);
  const { id: productId } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState('invoiceDate');
  const [sortDir, setSortDir] = useState('desc');
  const [
    filteredTotalInvoiceQuantity,
    setFilteredTotalInvoiceQuantity
  ] = useState(0);
  const prevPaginatedRows = useRef([]);

  const { isLoading, getProduct, selectedProduct } = useProducts();
  const { invoices, invsLoading } = useInventoryInvoiceData(productId);

  useEffect(() => {
    if (productId) {
      getProduct(productId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const { rows: invoiceRows } = useMemo(() => {
    if (!selectedProduct || !invoices)
      return { rows: [], totalProductCount: 0 };
    let productCount = 0;

    const invoiceData = invoices.map(i => {
      const productUses = i.invoiceProducts.filter(
        invoice => invoice.productId === selectedProduct.productId
      );

      let totalCost = 0;
      let invoiceQuantity = 0;
      const productUseUnits = [
        ...new Set(
          productUses.map(({ totalQuantityUnit }) => totalQuantityUnit)
        )
      ];

      productUses.forEach(productUse => {
        totalCost += productUse.totalCostValue;
        if (productUseUnits.length === 1) {
          invoiceQuantity += productUse.totalQuantityValue;
        } else {
          invoiceQuantity += productUse.totalQuantityValueInStandardPackageUnit;
        }
        productCount += productUse.totalQuantityValue;
      });

      return {
        id: i.id,
        invoiceName: i.invoiceNumber,
        invoiceDate: i.invoiceDate,
        invoiceCropSeasons: i.cropSeasons,
        totalCost,
        costPerUnit: Maths.divide(totalCost, invoiceQuantity),
        invoiceQuantity,
        unit:
          productUseUnits.length === 1
            ? productUseUnits[0]
            : selectedProduct.stdPackageUnit
      };
    });
    return { rows: invoiceData, totalProductCount: productCount };
  }, [selectedProduct, invoices]);

  const filteredAndSortedInvoices = useMemo(() => {
    const filtered = invoiceRows.filter(row => {
      const invoiceDate = new Date(row.invoiceDate);
      const cropFilter =
        cropSeasons.length === 0 ||
        row.invoiceCropSeasons.some(crop =>
          cropSeasons.includes(crop.seasonId)
        );
      const dateFilter =
        !dateRange ||
        dateRange.length === 0 ||
        (dateRange[0] &&
          !dateRange[1] &&
          invoiceDate >= new Date(dateRange[0])) ||
        (dateRange[0] &&
          dateRange[1] &&
          invoiceDate >= new Date(dateRange[0]) &&
          invoiceDate <= new Date(dateRange[1]));
      return cropFilter && dateFilter;
    });
    return tableSort(filtered, sortField, sortDir);
  }, [invoiceRows, sortField, sortDir, cropSeasons, dateRange]);

  const TABLE_COLUMNS = [
    {
      field: 'invoiceName',
      sortable: true,
      title: `Invoice Name (${filteredAndSortedInvoices.length})`
    },
    {
      field: 'invoiceDate',
      sortable: true,
      title: 'Invoice Date'
    },
    {
      field: 'totalCost',
      sortable: true,
      title: 'Total Cost'
    },
    {
      field: 'costPerUnit',
      sortable: true,
      title: 'Cost per unit'
    },
    {
      field: 'invoiceQuantity',
      sortable: true,
      title: 'Invoice Quantity'
    },
    {
      field: 'actions'
    }
  ];

  const paginatedRows = filteredAndSortedInvoices.slice(
    page * PAGE_COUNT,
    (page + 1) * PAGE_COUNT
  );

  useEffect(() => {
    if (
      JSON.stringify(prevPaginatedRows.current) !==
      JSON.stringify(paginatedRows)
    ) {
      const quantities = paginatedRows.map(invoice => ({
        invoiceQuantity: invoice.invoiceQuantity,
        unit: invoice.unit
      }));
      prevPaginatedRows.current = paginatedRows;

      // Calculate total invoice quantity
      const totalQuantity = quantities.reduce(
        (sum, { invoiceQuantity, unit }) => {
          if (unit === selectedProduct?.purchasedUnit) {
            return sum + invoiceQuantity;
          }
          const { Value } = getConvertUnitFromTo(
            invoiceQuantity,
            unit,
            selectedProduct?.purchasedUnit,
            { ...selectedProduct }
          );
          return sum + Value;
        },
        0
      );
      setFilteredTotalInvoiceQuantity(totalQuantity);
    }
  }, [paginatedRows, selectedProduct]);

  const handleSort = (field, dir) => {
    const _dir = !dir ? 'asc' : dir;
    setSortField(field);
    setSortDir(_dir);
  };

  const isTableLoading = !selectedProduct || invsLoading || isLoading;
  const isEmpty = paginatedRows.length === 0;
  const displayTotalInvoiceQuantity =
    dateRange?.length || cropSeasons?.length
      ? filteredTotalInvoiceQuantity
      : selectedProduct?.purchased;

  return (
    <div className="flex" style={{ height: 'calc(100% - 3rem)' }}>
      <div className="flex-1 overflow-scroll">
        <LandingTable
          items={paginatedRows}
          isLoading={isTableLoading}
          isEmpty={isEmpty}
          emptyState={() => null}
          tableColumns={TABLE_COLUMNS}
          onSort={handleSort}
          sortBy={sortField}
          sortDir={sortDir}
          wrapperClassName="flex-1 h-fit"
        >
          {({ rows }) => {
            return rows.map(invoice => (
              <Table.Row key={invoice.id}>
                <Table.Cell width="auto">{invoice.invoiceName}</Table.Cell>
                <Table.Cell width="auto">
                  {format(new Date(invoice.invoiceDate), 'MMM dd, u')}
                </Table.Cell>
                <Table.Cell width="auto">
                  {formatter.format(invoice.totalCost)}
                </Table.Cell>
                <Table.Cell width="auto">{`${formatter.format(
                  invoice.costPerUnit
                )} / ${getAbbreviatedUnit(invoice.unit)}`}</Table.Cell>
                <Table.Cell width="auto">
                  {formatNumber(
                    invoice.invoiceQuantity,
                    getAbbreviatedUnit(invoice.unit)
                  )}
                </Table.Cell>
                <Table.Cell width="auto">
                  <div className="w-full flex flex-row justify-end">
                    <Link
                      to={`${paths.invoices}/${invoice.id}/view`}
                      className="flex flex-row product-list-table-action"
                      onClick={() =>
                        amplitude.sendEventToAmplitude(
                          amplitude.events.epic.ProductList.viewInvoice,
                          { productId: invoice.id }
                        )
                      }
                    >
                      <img className="mr-1" src={ViewIcon} alt="view icon" />
                      <span>View</span>
                    </Link>
                  </div>
                </Table.Cell>
              </Table.Row>
            ));
          }}
        </LandingTable>
        {!isTableLoading && filteredAndSortedInvoices.length > 0 ? (
          <div className="w-full px-6 border-1 border-t-0 border-neutral-20">
            {CwFPagination(
              filteredAndSortedInvoices.length,
              page,
              setPage,
              () => (
                <div className="bg-banner-alert-blue-10 rounded py-1 px-3">
                  <span>Total Invoiced Quantity: </span>
                  <span className="font-semibold">
                    {formatNumber(
                      displayTotalInvoiceQuantity,
                      getAbbreviatedUnit(selectedProduct?.purchasedUnit)
                    )}
                  </span>
                </div>
              )
            )}
          </div>
        ) : null}
        {!isTableLoading && isEmpty && (
          <ProductDetailTableEmptyState
            type="invoices"
            totalItemCount={invoiceRows.length}
            onButtonClick={() => history.push(routesForPPLToCreate.invoice)}
          />
        )}
      </div>
    </div>
  );
};

ProductDetailInvoiceTable.defaultProps = {
  dateRange: null
};

ProductDetailInvoiceTable.propTypes = {
  cropSeasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.any)
};

export default ProductDetailInvoiceTable;
