import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner, Toast } from '@agconnections/grow-ui';
import { cloneDeep } from 'lodash';
import { useFormikContext } from 'formik';
import ProductsSideBar from 'components/ProductSideBar';
import useProductsData from 'hooks/useProductData';
import InvoiceProductTable from './components/InvoiceProductTable';

function InvoiceProductsTab({ memberRole, memberFinancialAccess }) {
  const { products, loading } = useProductsData();
  const { values, setFieldValue } = useFormikContext();
  // eslint-disable-next-line no-unused-vars
  const [toastRenderContents, setToastRenderContents] = useState(null);
  const [toastHasNotBeenClosed, setToastHasNotBeenClosed] = useState(true);
  const [, setStubbedAddedProducts] = useState(products?.slice(0, 2));
  const [customProductCreated, setCustomProductCreated] = useState(false);

  useEffect(() => {
    setStubbedAddedProducts(products?.slice(0, 2));
  }, [products]);

  const removeProduct = index => {
    const stubbedAddedProductsCopy = cloneDeep(values?.invoiceProducts);
    stubbedAddedProductsCopy.splice(index, 1);
    setStubbedAddedProducts(stubbedAddedProductsCopy);
    setFieldValue('invoiceProducts', stubbedAddedProductsCopy);
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="flex justify-between h-full">
          {toastHasNotBeenClosed ? (
            <Toast
              icon="error"
              onClose={() => {
                setToastHasNotBeenClosed(false);
              }}
            >
              {toastRenderContents}
            </Toast>
          ) : null}
          <div className="w-3/12">
            <ProductsSideBar
              memberRole={memberRole}
              memberFinancialAccess={memberFinancialAccess}
              setCustomProductCreated={setCustomProductCreated}
              productFieldName="invoiceProducts"
            />
          </div>
          <div className="ml-4 flex-grow">
            <InvoiceProductTable
              products={values?.invoiceProducts}
              removeProduct={removeProduct}
              memberRole={memberRole}
              memberFinancialAccess={memberFinancialAccess}
              setCustomProductCreated={setCustomProductCreated}
              customProductCreated={customProductCreated}
            />
          </div>
        </div>
      )}
    </>
  );
}

InvoiceProductsTab.propTypes = {
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired
};

export default InvoiceProductsTab;
